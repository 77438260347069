<template>
  <div class="header__wrapper">
    <div class="header__rehau">
      <div class="header__rehau-logo">
        <img src="../assets/Rehau_logotype.svg" alt="logo">
      </div>
      <div class="header__rehau-slogan">
        <div class="header__rehau-slogan-title">
          Конфигуратор фасадов
        </div>
      </div>
    </div>
    <div class="header__info">
      <div class="header__info-tel">
        <a href="tel:+78005553355" class="rh4_header__phone">+7 (800) 555 33 55</a>
      </div>
      <div class="header__info-location">
        <button
            @click="login"
            id="btnnext"
            type="button"
            class="btn-login"
          >
          {{user.username != null ? user.username + user.organisation.name : 'Вход для дилера'}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";
export default {
  name: "Header",
  data() {
      return {
      }
    },
    computed: {
      ...Vuex.mapGetters({
        user: 'auth/user'
      }),

      currentYear() {
        return new Date().getFullYear()
      }
    },
    methods: {
      login() {
        this.$router.push("/order")
      },
      ...Vuex.mapActions({
        check: 'auth/check'
      }),
    },
    created() {
      this.check()
    }
}
</script>

<style scoped>
.btn-login{
  width:150px;
  margin:0 auto;
  padding: 8px 0;
  border:0;
  background-color:#58BCAF;
  color:#fff;
  font-size:100%;
}
.btn-login:hover,
.btn-login:focus{
  background-color: #000000;
  outline: none;
}
  .header__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  a {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
  }

  .header__rehau-logo {
    width: 200px;
    height: 85px;
  }

  .header__rehau-slogan {
    display: flex;
    align-items: center;
  }

  .header__rehau-slogan {
    display: flex;
    align-items: center;
  }

  @media (max-width: 420px) {
      .header__rehau-slogan {
        display: flex;
        align-items: center;
      }

      img {
          max-width: 150px !important;
      }

      .header__rehau-logo {
        display: flex;
        align-items: center;
        width:  150px;
        height: 85px;
      }

      .btn-login{
        width:110px;
        margin:0 auto;
        padding:8px 0;
        border:0;
        background-color:#58BCAF;
        color:#fff;
        font-size:100%;
      }

      .header__info {
        display: flex !important;
        width: 100%;
        flex-direction: row-reverse !important;
        justify-content: space-around !important;
        margin-bottom: 10px;
      }

      .header__rehau-slogan-title {
        font-size: 21px !important;
        font-weight: bold;
        color:#000000;
      }

      a {
        font-weight: bold;
        font-size: 17px;
        color: #000000;
      }
    }

  .header__rehau-slogan-title {
    font-size:24px;
    font-weight: bold;
    color:#000000;
  }

  .header__rehau {
      display: flex;
      justify-content: space-between;

    }

  .header__info {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

  .header__info-location {
    display: flex;
    align-items: center;
  }

  .header__info-tel {
    display: flex;
    align-items: center;
  }
</style>