<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="/">РЕХАУ {{currentPageHeader}}</a>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item">
          <a v-if="can('analitic')" class="nav-link active" href="#" @click="toAnalitic">Аналитика</a>
        </li>
        <li class="nav-item">
          <a v-if="can('showorderedit') || can('updateOrders  ')" class="nav-link active" href="#" @click="toManagerOrderList">Редактирование заказов</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="#"  @click="toOrderList">Список заказов</a>
        </li>
        <li class="dropdown">
          <a  @click="isHideMenu = !isHideMenu" href="#">{{user.username}} {{user.organisation ? user.organisation.name :''}} <span class="caret"></span></a>
          <ul
            class="dropdown-menu"
            style="border-radius:1px;"
            :class="{show: !isHideMenu}"
          >
            <li>
              <a class="dropdown-item" @click="toChangePassword">Сменить пароль</a>
            </li>
            <li>
              <div class="dropdown-divider"></div>
            </li>
            <li>
              <a v-if="can('rbacManage')" class="dropdown-item" @click="toUsers">Пользователи</a>
            </li>
            <li>
              <a v-if="can('settings')" class="dropdown-item" @click="toPekCities">Города ПЭК</a>
            </li>
            <li>
              <a v-if="can('settings')" class="dropdown-item" @click="toWarehouses">Склады</a>
            </li>
            <li>
              <a v-if="can('settings')" class="dropdown-item" @click="toDealers">Дилеры</a>
            </li>
            <li>
              <a v-if="can('settings')" class="dropdown-item" @click="toType">Типы изделий</a>
            </li>
            <li>
              <a v-if="can('settings')" class="dropdown-item" @click="toCollection">Коллекции</a>
            </li>
            <li>
              <a v-if="can('settings')" class="dropdown-item" @click="toPrice">Прайс</a>
            </li>
            <li>
              <a v-if="can('settings')" class="dropdown-item" @click="toSettings">Настройки</a>
            </li>
            <li>
              <div class="dropdown-divider"></div>
            </li>
            <li>
              <a class="dropdown-item" @click="toLogout">Выход</a>
            </li>
          </ul>
        </li>
      </ul>

    </div>
  </nav>
</template>


<script>
  import Vuex from 'vuex'
  import Acl from '../../../utils/acl'

  export default {
    mixins: [Acl],
    name: 'navbar',
    data() {
      return {
        isHideMenu: true,
      }
    },
    computed: {
      ...Vuex.mapGetters({
        isAuthenticated: 'auth/isAuthenticated',
        user: 'auth/user',
        currentPageHeader: 'app/currentPageHeader',
        host: 'app/apiUrl',
      }),
    },
    methods: {
      toLogout() {
        this.$router.push('/logout')
        this.isHideMenu = !this.isHideMenu
      },
      toChangePassword() {
        this.$router.push('/changepassword')
        this.isHideMenu = true
      },
      toUsers() {
        this.$router.push('/users')
        this.isHideMenu = true
      },
      toPekCities() {
        this.$router.push('/pekcity')
        this.isHideMenu = true
      },
      toWarehouses() {
        this.$router.push('/warehouses')
        this.isHideMenu = true
      },
      toManagerOrderList() {
        this.$router.push('/managerorderlist')
        this.isHideMenu = true
      },
      toDealers() {
        this.$router.push('/dealers')
        this.isHideMenu = true
      },
      toType() {
        this.$router.push('/type')
        this.isHideMenu = true
      },
      toCollection() {
        this.$router.push('/collection')
        this.isHideMenu = true
      },
      toSettings() {
        this.$router.push('/settings')
        this.isHideMenu = true
      },
      toPrice() {
        this.$router.push('/price')
        this.isHideMenu = true
      },
      toOrderList() {
        this.$router.push('/order')
        this.isHideMenu = true
      },
      toAnalitic() {
        this.$router.push('/analitic')
        this.isHideMenu = true
      },
    }
  }
</script>

<style>
  .menushow {
    transform: translate3d(-202px, 27px, 0px);
  }
</style>
