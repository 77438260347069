<template>
  <section>
    <section id="2step" v-if="!this.$store.getters.stepThree">
      <div :style="{ border: borderStyle, margin: '8px 0' }" @click="setBlure">
        <div class="detail-block clearfix" style="border: 0px solid #C7C7C7;margin: 0px 0;">
          <!-- first line -->
          <section>
            <p class="detail-title col-lg-8">
              <span class="col-lg-2">Деталь{{idx+1}}</span>
              <span class="col-sm-4">
              <markdown
                :label="'Тип изделия'"
                :description="typeDesc"
                :img-src="typeImg"
              ></markdown>
              <select :id="idx+'1'"
                      class="form-control selectpicker slate form-step2"
                      @change.prevent="updateType" v-model="typeId">
                <option v-for="type in types" :key="type.type_id" v-bind:value="type.type_id">{{type.name}}</option>
              </select>
            </span>
              <span class="col-sm-3">
              <label class="label-head">Коллекция</label>
              <select :id="idx+'2'"
                      class="col-sm-6 form-control selectpicker slate form-step2"
                      @change.prevent="updateCollection(1)" v-model="collectionId">
                <option v-for="col in collections" :key="col.collection_id" v-bind:value="col.collection_id">{{col.name}}</option>
              </select>
            </span>
              <span class="col-sm-3">
                <div v-if="this.renderDirect" class="form-group select-detail">
                  <div class="form-group field-positions-textures ">
                    <label class="label-head">Направление текстуры</label>
                    <select
                      :id="idx+'5'"
                      class="form-control  form-step2 posinput selectpicker slate"
                      @change.prevent="updatePositionText" v-model="textures">
                      <option value="10">Горизонтальная</option>
                      <option value="20">Вертикальная</option>
                    </select>
                  </div>
              </div>
            </span>
            </p>
            <p class="detail-delete"><a class="deletepos" @click.prevent="deletePosition">Удалить деталь</a></p>
            <p class="detail-copy"><a class="copypos" @click.prevent="copyPosition">Скопировать деталь</a></p>
            <div class="clear" style="height:20px;"></div>
          </section>
          <!-- second line -->
          <section>
            <div class="col-sm-6">
              <div class="col-sm-6">
                <div class="row">
                  <div class="detail-box clearfix">
                    <img :src="facadeImg" alt="Поверхность">
                    <div class="form-group select-detail">
                      <div class="form-group">
                        <label class="label-head">Поверхность</label>
                        <select
                          :id="idx+'3'"
                          class="form-control selectpicker slate form-step2"
                          @change.prevent="setFacade" v-model="priceId">
                          <option v-for="facade in facades" :key="facade.price_id" v-bind:value="facade.price_id">{{facade.design}}
                            {{facade.colorcode}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div><!--/.detail-box -->
                </div><!--/.row -->
              </div>
              <div class="col-sm-3">
                <div class="form-group select-detail">
                  <label v-if="!renderHandle" class="label-head">Высота</label>
                  <markdown v-if="renderHandle"
                            :label="'Высота:'"
                            description="'Размер фасадов вносится с учетом размера ручки'"
                  ></markdown>
                  <div class="form-group">
                    <input
                      :id="idx+'6'"
                      type="text"
                      class="form-control form-step2"
                      @change.prevent="updatePosition"
                      v-model="lenght" :placeholder="'от ' + minLenght + ' до ' + mLenght"><span
                    class="cmtspan">мм</span>

                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group select-detail">
                  <label v-if="!renderHandle" class="label-head">Ширина</label>
                  <markdown v-if="renderHandle"
                            :label="'Ширина'"
                            :description="'Размер фасадов вносится с учетом размера ручки'"
                  ></markdown>
                  <div class="form-group">
                    <input
                      :id="idx+'7'"
                      type="text" class="form-control form-step2" @change.prevent="updatePosition"
                      v-model="width" :placeholder="'от ' + minWidth + ' до ' + mWidth"><span class="cmtspan">мм</span>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="col-sm-3">
                <div class="form-group select-detail">
                  <label class="label-head">Площадь</label>
                  <div class="form-group">
                    <input
                      type="text" class="form-control form-step2 dsb" placeholder="площадь м2"
                      :value="square" disabled><span class="cmtspan">м2</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group select-detail">
                  <label class="label-head">Цена 1 детали</label>
                  <div class="form-group">
                    <input type="text" class="form-control form-step2 dsb"
                           :value="oneCalcPrice" placeholder="цена 1 детали" disabled><span class="cmtspan">{{moneyRu ? '&#8381;' : 'Р'}}</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group select-detail">
                  <label class="label-head">Количество</label>
                  <div class="form-group">
                    <input type="text" class="form-control form-step2"
                           :id="idx+'8'"
                           @change.prevent="updatePosition" v-model="amount" placeholder="кол-во"
                           style="max-width:50px;"><span class="cmtspan">шт</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group select-detail">
                  <label class="label-head">Стоимость всех деталей</label>
                  <div class="form-group">
                    <input type="text" class="form-control form-step2 dsb"
                           placeholder="стоимость всех деталей " :value="allDetailCost" disabled><span class="cmtspan">{{moneyRu ? '&#8381;' : 'Р'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <p class="footnote">
                <sup>*</sup> Внешний вид готового изделия может отличаться от изображения
              </p>
            </div>
            <div class="clear"></div>
          </section>
          <div class="add-info clearfix">
            <!-- third line -->
            <section>
              <div class="col-sm-5">
                <div class="col-sm-4">
                  <div class="form-group select-detail">
                    <div class="form-group">
                      <markdown
                        :label="'Ориентировочный срок изготовления'"
                        :description="'Срок указан в рабочих днях без учета поставки с производства'"
                      ></markdown>
                      <input type="text" class="form-control form-step2 posinput lenght dsb" :value="prepareDays"
                             disabled>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3"></div>
                <div class="col-sm-5 text-right">
                  <div class="form-group select-detail mob-form-detail">
                    <label class="label-head">Чертеж присадок</label>
                    <div class="form-group">
                      <label class="fl" :for="'file' + idx" style="margin-top:15px;">
                        <input type="file" class="form-control form-step2"
                               placeholder="Загрузить чертеж детали"
                               :id="'file' + idx"
                               ref="file"
                               @change="uploadFile">{{file}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="col-sm-3">
                  <div class="form-group select-detail">
                    <markdown
                      :label="'Выбор места присадки'"
                      :labelClass="'label-head'"
                      :description="settings.desc_loop"
                    ></markdown>
                    <div class="form-group">
                      <select
                        :id="idx+'10'"
                        class="form-control form-step2 posinput selectpicker slate "
                        v-model="looptype" @change.prevent="updatePosition"
                      >
                        <option value="0">Вид присадки</option>
                        <option value="1">Слева</option>
                        <option value="2">Справа</option>
                        <option value="3">Сверху</option>
                        <option value="4">Снизу</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 ">
                  <div class="form-group select-detail">
                    <label class="label-head">Цена 1 пр-ки</label>
                    <div class="form-group">
                      <input type="text" class="form-control form-step2 dsb" placeholder="цена 1 пр-ки"
                             :value="getLoopPrice" disabled><span class="cmtspan">{{moneyRu ? '&#8381;' : 'Р'}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group select-detail">
                    <markdown
                      :label="'Кол-во'"
                      :labelClass="'label-head'"
                      :description="'кол-во присадок на 1 деталь'"
                    ></markdown>
                    <div class="form-group">
                      <input
                        :id="idx+'11'"
                        type="text" class="form-control form-step2" placeholder="кол-во"
                        v-model="loopcount" @change.prevent="updatePosition"
                        :style="'max-width:50px;' + [this.loopcount < 1 & this.looptype > 0 ? 'border: solid red 1px' : '']"><span
                      class="cmtspan">шт</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group select-detail">
                    <markdown
                      :label="'Стоимость всех присадок'"
                      :description="'Стоимость присадок для всех деталей в этой форме'"
                    ></markdown>
                    <div class="form-group">
                      <input type="text" class="form-control form-step2 dsb" placeholder="Стоимость всех присадок "
                             :value="allLoopCost" disabled><span class="cmtspan">{{moneyRu ? '&#8381;' : 'Р'}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-5">
              </div>
              <div class="col-sm-7">
                <div class="col-sm-4">
                  <div class="form-group select-detail">
                    <div v-if="renderHandle" class="form-group" style="">
                      <label class="label-head">Выбор расположения ручки</label>
                      <select
                        :id="idx+'9'"
                        :style="'' + [handleType < 1 ? 'border: solid red 1px' : '']"
                        class="form-control form-step2 posinput selectpicker slate "
                        v-model="handleType" @change.prevent="updatePosition"
                      >
                        <option value="0">Выбор расположения ручки</option>
                        <option value="1">Слева</option>
                        <option value="2">Справа</option>
                        <option value="3">Сверху</option>
                        <option value="4">Снизу</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group select-detail">
                    <div class="form-group" v-if="renderHandle" style="">
                      <label class="label-head">Длина ручек (1 ручка макс. 1,5м)</label>
                      <input type="text" class="form-control form-step2 dsb"
                             :value="handleLength" placeholder="длина ручек " disabled>
                      <span class="cmtspan">мм</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group select-detail">
                    <div class="form-group" v-if="renderHandle" style="">
                      <label class="label-head">Стоимость всех ручек</label>
                      <input type="text" class="form-control form-step2 dsb"
                             :value="handlePrice" placeholder="стоимость всех ручек" disabled>
                      <span class="cmtspan">{{moneyRu ? '&#8381;' : 'Р'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <!-- fifthline -->
        <div class="row" id="mob-row" style="background-color: #D9D9D9;padding:0;margin:1px;">
          <div class="col-sm-6">
            <div class="col-sm-12 ">
              <div class="form-group select-detail">
                <label class="label-head"></label>
                <div class="form-group comment-mob">
                  <input
                    :id="idx+'12'"
                    type="text"
                    class="form-control form-step2 bljad"
                    placeholder="комментарий к детали"
                    style="width:100%;"
                    v-model="comment" @change.prevent="updatePosition"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="col-sm-4">
              <div class="form-group select-detail">
                <label class="label-head">Общая площадь</label>
                <div class="form-group">
                  <input type="text" class="form-control form-step2 dsb" placeholder="Общая площадь"
                         :value="allDetailSquare"
                         disabled><span class="cmtspan">м2</span>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group select-detail">
                <label class="label-head">Общий вес</label>
                <div class="form-group">
                  <input type="text" class="form-control form-step2 dsb" placeholder="Общий вес"
                         :value="allDetailWeight"
                         style="max-width: 50px;"
                         disabled><span class="cmtspan">кг</span>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group select-detail">
                <h4 style="padding:2px;margin-top:2px;margin-bottom:2px;">СУММА</h4>
                <h3 style="padding:2px;margin-top:2px;">{{sum}} {{moneyRu ? '&#8381;' : 'Р'}} </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
  import Http from '../../utils/http'
  import Markdown from "../../utils/markdown";
  import Vuex from 'vuex'

  export default {
    props: ['position', 'idx', 'types'],
    components: {
      Markdown: Markdown
    },
    data() {
      return {
        facades: [],
        collections: [],
        collectionId: null,
        typeId: null,
        priceId: null,
        facadeImg: null,
        maxLenght: null,
        maxWidth: null,
        minLenght: null,
        minWidth: null,
        loopPrice: null,
        looptype: null,
        loopcount: null,
        comment: null,
        lenght: null,
        width: null,
        handleType: null,
        amount: 1,
        bigUrl: null,
        textures: 10,
      }
    },
    watch: {
      positionId: () => {
        if(this.positionId!= null) {
          this.setPosProps()
        }
      },
    },
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        settings: 'app/settings'
      }),
      collectionLength() {
        return this.collections.length
      },
      mWidth() {
        if (this.textures == 20 && this.renderDirect) {
          return this.maxLenght
        }
        return this.maxWidth
      },
      mLenght() {
        if (this.textures == 20 && this.renderDirect) {
          return this.maxWidth
        }
        return this.maxLenght
      },
      renderHandle() {
        if (!(this.typeId > 0)) {
          return false
        }
        for (let i = 0; i < this.types.length; i++) {
          if (this.types[i].type_id == this.typeId) {
            if (this.types[i].handle > 0) {
              return true
            }
          }
        }

        return false
      },
      renderDirect() {
        if (!(this.collectionId > 0) || this.collections == null) {
          return false
        }
        for (let i = 0; i < this.collections.length; i++) {
          if (this.collections[i].collection_id == this.collectionId) {
            console.log(this.collections[i])
            if (this.collections[i].isvector > 0) {
              return true
            }
          }
        }
        return false
      },
      oneCalcPrice() {
        var res = this.position.one_calcprice
        if (res > 0) {
          res = res.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
        }
        return res
      },
      positionId() {
        return this.position.position_id
      },
      sum() {
        var res = this.position.sumvalue
        if (res > 0) {
          return res.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
        }

        return 0
      },
      square() {
        if (this.lenght > 0 && this.width > 0) {
          return Number((this.lenght * this.width / 1000000).toFixed(3))
        }

        return 0
      },
      weight() {
        var sq = this.square
        if (sq > 0) {
          return Number((sq * 17 * 1.05).toFixed(2))
        }

        return 0
      },
      allDetailSquare() {
        var sq = this.square
        if (sq > 0 && this.amount > 0) {
          return Number((sq * this.amount).toFixed(3))
        }

        return 0
      },
      allDetailWeight() {
        var wg = this.weight
        if (wg > 0 && this.amount > 0) {
          return Number((wg * this.amount).toFixed(2))
        }

        return 0
      },
      allDetailCost() {
        if (this.position.one_calcprice > 0 && this.amount > 0) {
          var res = " " + Number((this.position.one_calcprice * this.amount).toFixed(2))
          return res.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
        }

        return 0
      },
      allLoopCost() {
        var loopPrice = this.position.loop_price
        if (loopPrice > 0 && this.loopcount > 0 && this.looptype > 0) {
          var res = " " + Number((loopPrice * this.amount * this.loopcount).toFixed(2))
          return res.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
        }

        return 0
      },
      getLoopPrice() {
        return this.position.loop_price
      },
      handlePrice() {
        return this.position.handle_price
      },
      handleLength() {
        return this.position.handle_length
      },
      file() {
        return this.position.filename ? this.position.filename : 'Загрузка файла'
      },
      borderStyle() {
        return this.idx === this.$store.getters.blured ? '1px solid red' : '1px solid #C7C7C7'
      },
      typeDesc() {
        var type = this.getType
        if (type != null) {
          return type.description
        }
        return ""
      },
      typeImg() {
        var type = this.getType
        if (type != null) {
          return "https://api.fronts.shop.rhsolutions.ru/images/types/" + type.filename
        }
        return ""
      },
      prepareDays() {
        for (var i = 0; i < this.facades.length; i++) {
          if (+this.priceId == +this.facades[i].price_id) {
            return this.facades[i].prepare_days
          }
        }
        return 15
      },
      allHandleCount() {
        if (this.amount > 0 && this.handlePrice > 0) {
          return this.amount
        }

        return 0
      },
      getType() {
        if (this.typeId > 0) {
          for (var i = 0; i < this.types.length; i++) {
            if (this.typeId == this.types[i].type_id) {
              return this.types[i]
            }
          }
        }
        return null
      },
      moneyRu() {
        if (window.location.origin == 'http://rehau-kitchen.by' || window.location.origin == 'www.rehau-kitchen.by') {
          return false
        }
        return true
      }
    },
    methods: {
      updateType() {
        if (!(this.typeId > 0)) {
          this.typeId = this.types[0].type_id
        }
        Http.get(this.host + 'typev2/collectionbytype?id=' + this.typeId)
          .then((response) => {
            this.collections = response.data
            var potential = response.data[0].collection_id
            for (var i = 0; i < this.collectionLength; i++) {
              if (this.collections[i].collection_id == this.collectionId) {
                potential = null
                break
              }
            }
            if (potential != null) {
              this.collectionId = potential
            }
            this.updateCollection()
          }).catch((error) => {
          console.log(error)
        });
      },
      deletePosition() {
        this.$emit('deletePosition', this.positionId)
      },
      uploadFile() {
        var formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);
        formData.append('position_id', this.position.position_id)
        this.$store.dispatch('index/uploadPosition', formData)
      },
      copyPosition() {
        this.$store.dispatch('index/copyPosition', this.position.position_id)
      },
      setPosProps() {
        this.collectionId = this.position.collection_id
        this.typeId = this.position.type_id
        this.priceId = this.position.price_id
        this.amount = this.position.amount
        this.lenght = this.position.lenghtsize
        this.width = this.position.widthsize
        this.comment = this.position.comment
        this.loopcount = this.position.loopcount
        this.looptype = this.position.looptype
        this.loopPrice = this.position.loop_price
        this.maxLenght = +this.position.maxlenght
        this.maxWidth = +this.position.maxwidth
        this.minLenght = +this.position.minlenght
        this.handleType = +this.position.handle_type
        this.textures = +this.position.textures
        if (+this.position.minlenght > 0) {
          this.minLenght = +this.position.minlenght
          this.minWidth = +this.position.minlenght
        } else {
          this.minLenght = 100
          this.minWidth = 100
        }
      },
      updateCollection() {
        Http.get(this.host + 'pricev2/apilist?collection_id=' + this.collectionId + '&type_id=' + this.typeId)
          .then((response) => {
            this.facades = response.data
            var setId = this.facades[0].price_id
            if (this.priceId > 0) {
              for (let i = 0; i < this.facades.length; i++) {
                if (this.priceId == this.facades[i].price_id) {
                  setId = this.priceId
                }
              }
            }
            this.setFacade(setId)
          }).catch(function (error) {
          console.log(error)
        })
      },
      setImgUrl() {
        Http.post(this.host + 'index.php/ordersv2/getbackgroundurl', {
          collection_id: this.collectionId,
          price_id: this.priceId
        })
          .then((response) => {
            this.$store.commit('index/setCollectionImg', response.img)
          })
      },
      setFacade(priceId) {
        if (priceId > 0) {
          this.priceId = priceId
        }
        for (let i = 0; i < this.facades.length; i++) {
          if (this.facades[i].price_id == this.priceId) {
            this.maxLenght = +this.facades[i].lenghtsize
            this.maxWidth = +this.facades[i].widthsize
            if (this.facades[i].min_length != null) {
              this.minLenght = +this.facades[i].min_length
            } else if (this.facades[i].minlenght) {
              this.minLenght = +this.facades[i].minlenght
            } else {
              this.minLenght = 100
            }
            if (this.facades[i].min_width != null) {
              this.minWidth = +this.facades[i].min_width
            } else if (this.facades[i].minlenght) {
              this.minWidth = +this.facades[i].minlenght
            } else {
              this.minWidth = 100
            }
          }
        }

        this.setFacadeImg()
        this.setImgUrl()
        this.updatePosition()
      },
      setFacadeImg() {
        for (var i = 0; i < this.facades.length; i++) {
          if (this.priceId === this.facades[i].price_id) {
            this.facadeImg = this.host + encodeURI(this.facades[i].price_filepath)
          }
        }
      },
      updatePositionText() {
        var a = this.lenght
        this.lenght = this.width
        this.width = a
        this.updatePosition()
      },
      updatePosition() {
        this.validateLimits()
        this.savePosition()
      },
      savePosition() {
        this.validateTypes()
        Http.post(this.host + 'ordersv2/updateposition',
          {
            position_id: this.positionId,
            collection_id: this.collectionId,
            type_id: this.typeId,
            price_id: this.priceId,
            widthsize: this.width,
            lenghtsize: this.lenght,
            amount: this.amount,
            comment: this.comment,
            loopcount: this.loopcount,
            looptype: this.looptype,
            handle_type: this.handleType,
            textures: this.textures,
          }
        ).then(() => {
          this.$store.dispatch('index/getPositions')
        }).catch((error) => {
          console.log(error)
        })
      },
      validateLimits() {
        if (this.lenght > this.mLenght || this.lenght < this.minLenght) {
          this.lenght = null
        } else if (this.lenght < this.minLenght) {
          this.lenght = null
        }
        if (this.minLenght == this.mLenght) {
          this.lenght = this.minLenght
        }
        if (this.width > this.mWidth) {
          this.width = null
        } else if (this.width < this.minWidth) {
          this.width = null
        }
        if (this.minWidth == this.mWidth) {
          this.width = this.minWidth
        }
      },
      validateTypes() {
        if (this.amount < 1) {
          this.amount = 1
        }
        if (this.amount > 50) {
          this.amount = 50
        }
        if (!(this.looptype > 0)) {
          this.looptype = 0
        }
        if (!(this.typeId > 0)) {
          this.typeId = this.types[0].type_id
        }
      },
      setBlure() {
        this.$store.commit('index/setBlure', this.idx)
      },
    },
    created() {
      this.setPosProps()
      this.updateType()
      this.updateCollection()

    }

  }
</script>
<style scoped>
  .dsb {
    color: #4E4E4E;
  }

  .cmtspan {
    font-size: 16px;
    margin-top: 5px;
    float: left;
  }

  .select-detail input[type="text"].form-control {
    font-size: 16px;
  }

  label.fl {
    display: block;
    cursor: pointer;
  }

  label.fl::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    content: url(http://s1.iconbird.com/ico/0612/GooglePlusInterfaceIcons/w18h181338911550clip.png);
  }

  label [type=file] {
    display: none;
  }

  .tbdiv {
    float: left;
    width: 100px;
  }

  .label-head {
    font-size: 14px;
  }

  .detail-box {
    padding-left: 135px;
    padding-right: 8px;
  }

  .detail-box img {
    position: absolute;
    width: 130px;
    height: 81px;
    top: 0;
    left: 0;
    border: 1px solid #C7C7C7;
  }

  .col-sm-3, .col-sm-2, .col-sm-4 {
    padding-right: 5px;
    padding-left: 10px;
  }

  .footnote {
    text-align: left;
    margin-left: 135px;
    margin-top: -5px;
  }

  .bljad::placeholder {
    color: #818181;
  }

  @media (max-width: 420px) {
    .detail-title .col-lg-2 {
      padding: 0px;
    }
  }
</style>

