<template>
  <section :class='isActiveCss'>
      <div class="form-row">
        <div class="col-xs-2">
          <input class="form-control" @keyup="findData" @change="findData" v-model="query" type="text">
        </div>
        <div class="col-xs-1">
          <button class="btn btn-primary" @click="download" style="margin-top: 0px;">Скачать</button>
        </div>
        <div class="col-xs-1">
          <label for="file-upload" class="btn btn-primary" style="margin-top: 0px;padding-left: 12px;">Загрузить
          </label>
          <input id="file-upload" type="file" name="f" class="form-control " ref="file" @change="upload">
        </div>
        <div class="col-xs-4 col-xs-offset-4">
          <pagination class="pull-right" style="margin-top: 0px;" @setPage="setPage" :padding="2" :totalpages="pagination.total/pagination.limit" :page="pagination.page"></pagination>
        </div>
      </div>
      <table class="table table-striped table-bordered table-hover">
        <tr>
          <th>Коллекция</th>
          <th>Дизайн</th>
          <th> ColorCode</th>
          <th>MatNumber</th>
          <th>Min</th>
          <th>Длина</th>
          <th>Ширина</th>
          <th>Дизайн горизонт</th>
          <th>Дизайн большой</th>
          <th>Стоимость</th>
          <th>Стоимость BEL</th>
          <th>Тип кромки</th>
          <th>Код кромки</th>
          <th>Подготовка</th>
          <th>Сортировка</th>
          <th>Видимость</th>
          <th></th>
        </tr>
        <tbody v-for="model in datalist" :key="model.price_id">
          <tr v-if="edit == null || edit.price_id != model.price_id">
            <td>{{collectionName(model.collection_id)}}</td>
            <td>{{model.design}}</td>
            <td >{{model.colorcode}}</td>
            <td>{{model.mat_number}}</td>
            <td>{{model.minlenght}}</td>
            <td>{{model.lenghtsize}}</td>
            <td>{{model.widthsize}}</td>
            <td>
              <label :for="'upload-filepath' + model.price_id">
                <img v-if="model.price_filepath != null"
                     style="min-width: 70px;
                     width: 70px;
                     min-height: 20px;
                     background-color: #cfcfcf;"
                     :src="encodeURI(apiUrl +  model.price_filepath )" alt="">
                <span v-if="model.price_filepath == null || model.price_filepath == ''">Загрузить</span>
              </label>
              <input :id="'upload-filepath' + model.price_id" type="file" name="f" class="form-control " :ref="'filepath'+model.price_id" @change="uploadImg('filepath', model.price_id)">
            </td>
            <td>
              <label :for="'upload-bgfilepath' + model.price_id">
                <img v-if="model.price_bgfilepath != null"
                     style="min-width: 70px;
                     width: 70px;
                     min-height: 20px;
                     background-color: #cfcfcf;"
                     :src="encodeURI(apiUrl +  model.price_bgfilepath )" alt="">
                <span v-if="model.price_bgfilepath == null || model.price_bgfilepath == ''">Загрузить</span>
              </label>
              <input :id="'upload-bgfilepath' + model.price_id" type="file" name="f" class="form-control " :ref="'bgfilepath'+model.price_id" @change="uploadImg('bgfilepath', model.price_id)">
            </td>
            <td>{{model.price}}</td>
            <td>{{model.price_bel}}</td>
            <td>{{model.bordername}}</td>
            <td>{{model.bordercode}}</td>
            <td>{{model.prepare_days}}</td>
            <td>
              <span  class="glyphicon glyphicon-arrow-up" @click="up(model.price_id)"></span>
              <span  class="glyphicon glyphicon-arrow-down" @click="down(model.price_id)"></span>
            </td>
            <td>
              <span  v-if="model.is_unvisible == 1" class="glyphicon glyphicon-eye-close" @click="hide(model.price_id)"></span>
              <span  v-if="model.is_unvisible != 1" class="glyphicon glyphicon-eye-open" @click="hide(model.price_id)"></span>
            </td>
            <td width="100px;"><vue-confirmation-button
                    :css="'glyphicon glyphicon-trash'"
                    v-on:confirmation-success="deleteData(model.price_id)">
            </vue-confirmation-button></td>
          </tr>
        </tbody>

      </table>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import vueConfirmationButton from '../../utils/confirmButton'
  import pagination from "../../utils/pagination"
  import Http from '@/utils/http'

  export default {
    mixins: [Acl],
    name: 'type',
    data () {
      return {
        form:{
          name: 'Новый',
        },
        page: null,
        edit: null,
        query: null,
        isActiveCss: '',
      }
    },
    components: {
      VueConfirmationButton: vueConfirmationButton,
      pagination
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        datalist: 'price/datalist',
        pagination: 'price/pagination',
        collection: 'collection/datalist',
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader:'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        save: 'price/save',
        find: 'price/find',
        drop: 'price/delete',
        getCollection: 'collection/findAll'
      }),
      saveData(){
        this.setError(null)
        this.save(this.form)
                .then(() =>{
                  this.findData()
                })
      },
      deleteData(id){
        this.setError(null)
        this.drop(id)
                .then(() =>{
                  this.findData()
                })
      },
      setPage(i){
        this.page = i
        this.findData()
      },
      findData(){
        this.find({page:this.page, query: this.query})
      },
      collectionName(id){
        var name = ''
        this.collection.map(el => {
          if (el.collection_id == id) {
            name = el.name
          }
        })
        return name
      },
      up(id){
        Http.get(this.apiUrl + '/pricev2/up?id=' + id)
          .then(() => {
            this.findData()
          })
      },
      down(id){
        Http.get(this.apiUrl + '/pricev2/down?id=' + id)
          .then(() => {
            this.findData()
          })
      },
      hide(id){
        Http.get(this.apiUrl + '/pricev2/hide?id=' + id)
          .then(() => {
            this.findData()
          })
      },
      download(){
        window.location = this.apiUrl + 'pricev2/download'
        return
      },
      upload(){
        this.isActiveCss = 'disabled'
        this.setError(null)
        var formData = new FormData()
        formData.append('file', this.$refs.file.files[0])
        Http.post(this.apiUrl + '/pricev2/addlist',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        ).then(() => {
          this.findData()
          this.isActiveCss = ''
          document.getElementById('file-upload').value = null
        }).catch((err) => {
          this.setError(err)
          this.isActiveCss = ''
          document.getElementById('file-upload').value = null
        })
      },
      uploadImg(type, id){
        var formData = new FormData()
        var file = this.$refs[type + id]
        formData.append('img', file[0]['files'][0])
        formData.append('price_id', id)
        formData.append('type', type)
        Http.post(this.apiUrl + '/pricev2/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        ).then(() => {
          this.findData()
          document.getElementById('upload-'+type + id).value = null
        }).catch((err) => {
          this.setError(err)
        })
      },

    },
    created() {
      this.setHeader('Прайс')
      this.page = this.pagination.page
      this.getCollection()
      this.findData()
    }
  }
</script>
<style>
  input[type="file"] {
    display: none;
  }
  .city:hover{
    background-color: lightgrey;
  }
</style>
