<template>
  <section style="overflow-x: auto;" :class="isActiveCss">
    <div class="row" style="margin-left: 0px;">
      <div class="col-12">
        <div class="col-xs-1">
          <input class="form-control mx-input myinput" style="margin-left: 2px;float:left;"
                 @change="findOrders" placeholder="Номер" v-model="form.id">
        </div>
        <div class="col-xs-1">
          <date-picker input-class="form-control mx-input" @change="findOrders"
                       format="DD.MM.YYYY"  placeholder="от" style="margin-left: 2px;float:left;" v-model="form.from" valueType="format"></date-picker>
        </div>
        <div class="col-xs-1">
          <date-picker input-class="form-control mx-input" @change="findOrders" format="DD.MM.YYYY"   placeholder="до" style="margin-left: 2px;float:left;" v-model="form.to" valueType="format"></date-picker>
        </div>
        <div class="col-xs-3 col-xs-offset-1">
          <button class="btn btn-primary" style="margin-top: 0px;" @click="showField = !showField">Детализация</button>
          <button v-if="claimAvailable" style="margin-top: 0px;" class="btn btn-primary" @click="addClaim">+Рекламация</button>
        </div>

        <div class="col-xs-1">
          <a class="btn btn-primary" style="margin-top: 0px;" @click.prevent="download">Скачать</a>
        </div>
        <div class="col-xs-1">
          <label v-if="this.type != 'analitic'" for="file-upload" class="btn btn-primary" style="padding-left: 12px;margin-top: 0px;">Загрузить
          </label>
          <input id="file-upload" type="file" name="f" class="form-control " ref="file" @change="upload">
        </div>
        <div class="col-xs-3">
          <pagination class="pull-right" style="margin-top: 0px;" @setPage="setPage" :padding="2"
                      :totalpages="pagination.total/pagination.limit" :page="pagination.page"></pagination>
        </div>
      </div>
    </div>
    <table class="table table-hover table-bordered">
      <tr>
        <th>Номер заказа</th>
        <th v-if="type == 'analitic'">Номер салона</th>
        <th v-if="type == 'analitic'">ID дизайнера</th>
        <th>Рекл</th>
        <th>Статус</th>
        <th v-if="showField && type == 'analitic'" >Год</th>
        <th v-if="showField && type == 'analitic'" >Месяц</th>
        <th v-if="type == 'analitic'" >Регион</th>
        <th v-if="type == 'analitic'" >Сумма (от  - до)</th>
        <th v-if="type == 'analitic'" >Квадратура (от  - до)</th>
        <th>Дата подтвержденного <br/> заказа от клиента</th>
        <th>Производитель</th>
        <th>№ заказа SAP</th>
        <th>Дата подтверждения <br/> заказа от конфекционера</th>
        <th>Название клиента</th>
        <th v-if="showField" >Город(где находится дилер)</th>
        <th>Доставка</th>
        <th>Состав заказа</th>
        <th>Декор</th>
        <th>Планируемая дата <br/> поставки в Гжель</th>
        <th>Фактическая дата <br/> поступления в Гжель</th>
        <th v-if="showField && type == 'analitic'" > планируемый срок</th>
        <th v-if="showField && type == 'analitic'" >фактический срок</th>
        <th>Счет дилеру</th>
        <th>Счет диреру под петли <br/>ручки упаковку</th>
        <th>Комментарии к заказу</th>
      </tr>
      <tr>
        <th>
          <input class="form-control mx-input myinput" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.orderNum">
          <span > -</span>
        </th>
         <th v-if="type == 'analitic'">
          <input v-if="form.saloonnum != '-9' && form.saloonnum != '-99'" class="form-control mx-input myinput" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.saloonnum">
          <span  class="pnt" v-if="form.saloonnum != '-99'" @click="nullfield('saloonnum')">x</span>
          <span class="pnt" v-if="form.saloonnum != '-9'" style="margin-left:10px" @click="existfield('saloonnum')">v</span>
        </th>
        <th v-if="type == 'analitic'">
          <input v-if="form.designer_id != '-9' && form.designer_id != '-99'" class="form-control mx-input myinput" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.designer_id">
          <span  class="pnt" v-if="form.designer_id != '-99'" @click="nullfield('designer_id')">x</span>
          <span class="pnt" v-if="form.designer_id != '-9'" style="margin-left:10px" @click="existfield('designer_id')">v</span>
        </th>
        <th>
          <input v-if="form.claim_number != '-9' && form.claim_number != '-99'" class="form-control mx-input myinput" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.claim_number">
          <span  class="pnt" v-if="form.claim_number != '-99'" @click="nullfield('claim_number')">x</span>
          <span class="pnt" v-if="form.claim_number != '-9'" style="margin-left:10px" @click="existfield('claim_number')">v</span>
        </th>
        <th>
          <input v-if="form.status != '-9' && form.status != '-99'" class="form-control mx-input myinput" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.status">
          <span class="pnt" v-if="form.status != '-99'" @click="nullfield('status')">x</span>
          <span class="pnt" v-if="form.status != '-9'" style="margin-left:10px" @click="existfield('status')">v</span>
        </th>
        <th v-if="showField && type == 'analitic'">
        </th>
        <th v-if="showField && type == 'analitic'">
        </th>
        <th v-if="type == 'analitic'">
          <select class="form-control mx-input myinput" placeholder="доставка" style="margin-left: 2px;float:left;width:85px;" @change="findOrders" v-model="form.zone">
            <option value="">Все</option>
            <option value="10">Россия</option>
            <option value="20" >Беларусь</option>
          </select>
          <span > -</span>
        </th>
        <th v-if="type == 'analitic'" >
          <input v-if="form.sumvalue != '-9' && form.sumvalue != '-99'" class="form-control mx-input myinput" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.sumvalue">
          <span class="pnt" v-if="form.sumvalue != '-99'" @click="nullfield('sumvalue')">x</span>
          <span class="pnt" v-if="form.sumvalue != '-9'" style="margin-left:10px" @click="existfield('sumvalue')">v</span>
        </th>
        <th v-if="type == 'analitic'" >
          <input v-if="form.square != '-9' && form.square != '-99'" class="form-control mx-input myinput" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.square">
          <span class="pnt" v-if="form.square != '-99'" @click="nullfield('square')">x</span>
          <span class="pnt" v-if="form.square != '-9'" style="margin-left:10px" @click="existfield('square')">v</span>
        </th>
        <th>
          <date-picker v-if="form.clientAgreeDate != '-9' && form.clientAgreeDate != '-99'" input-class="form-control mx-input" @change="findOrders" format="DD.MM.YYYY"  placeholder="Подтв. клиента" style="margin-left: 2px;float:left;" v-model="form.clientAgreeDate" valueType="format"></date-picker>
          <span class="pnt" v-if="form.clientAgreeDate != '-99'" @click="nullfield('clientAgreeDate')">x</span>
          <span class="pnt" v-if="form.clientAgreeDate != '-9'" style="margin-left:10px" @click="existfield('clientAgreeDate')">v</span>
        </th>
        <th>
          <input v-if="form.creator != '-9' && form.creator != '-99'" class="form-control mx-input myinput" placeholder="Производитель" style="margin-left: 2px;float:left" @change="findOrders" v-model="form.creator">
          <span class="pnt" v-if="form.creator != '-99'" @click="nullfield('creator')">x</span>
          <span class="pnt" v-if="form.creator != '-9'" style="margin-left:10px" @click="existfield('creator')">v</span>
        </th>
        <th>
          <input v-if="form.sapnum != '-9' && form.sapnum != '-99'" class="form-control mx-input myinput" placeholder="№ заказа" style="margin-left: 2px;float:left" @change="findOrders" v-model="form.sapnum">
          <span class="pnt" v-if="form.sapnum != '-99'" @click="nullfield('sapnum')">x</span>
          <span class="pnt" v-if="form.sapnum != '-9'" style="margin-left:10px" @click="existfield('sapnum')">v</span>
        </th>
        <th>
          <date-picker v-if="form.confectAgreeDate != '-9' && form.confectAgreeDate != '-99'" input-class="form-control mx-input"
                       @change="findOrders" format="DD.MM.YYYY"  placeholder="Подтв. конфекционера" style="margin-left: 2px;float:left;" v-model="form.confectAgreeDate" valueType="format"></date-picker>
          <span class="pnt" v-if="form.confectAgreeDate != '-99'" @click="nullfield('confectAgreeDate')">x</span>
          <span class="pnt" v-if="form.confectAgreeDate != '-9'" style="margin-left:10px" @click="existfield('confectAgreeDate')">v</span>
        </th>
        <th>
          <input v-if="form.clientName != '-9' && form.clientName != '-99'"  class="form-control mx-input myinput" placeholder="Название клиента" style="margin-left: 2px;float:left" @change="findOrders" v-model="form.clientName">
          <span class="pnt" v-if="form.clientName != '-99'" @click="nullfield('clientName')">x</span>
          <span class="pnt" v-if="form.clientName != '-9'" style="margin-left:10px" @click="existfield('clientName')">v</span>
        </th>
        <th v-if="showField">
          <input v-if="form.town != '-9' && form.town != '-99'" class="form-control mx-input myinput" placeholder="Город" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.town">
          <span class="pnt" v-if="form.town != '-99'" @click="nullfield('town')">x</span>
          <span class="pnt" v-if="form.town != '-9'" style="margin-left:10px" @click="existfield('town')">v</span>
        </th>
        <th>
          <input v-if="form.region != '-9' && form.region != '-99'" class="form-control mx-input myinput" placeholder="доставка" style="margin-left: 2px;float:left" @change="findOrders" v-model="form.region">
          <span class="pnt" v-if="form.region != '-99'" @click="nullfield('region')">x</span>
          <span class="pnt" v-if="form.region != '-9'" style="margin-left:10px" @click="existfield('region')">v</span>
        </th>
        <th>
          <input v-if="form.contain != '-9' && form.contain != '-99'" class="form-control mx-input myinput" placeholder="Состав" style="margin-left: 2px;float:left" @change="findOrders" v-model="form.contain">
          <span class="pnt" v-if="form.contain != '-99'" @click="nullfield('contain')">x</span>
          <span class="pnt" v-if="form.contain != '-9'" style="margin-left:10px" @click="existfield('contain')">v</span>
        </th>
        <th>
          <input v-if="form.decor != '-9' && form.decor != '-99'" class="form-control mx-input myinput" placeholder="Декор" style="margin-left: 2px;float:left" @change="findOrders" v-model="form.decor">
          <span class="pnt" v-if="form.decor != '-99'" @click="nullfield('decor')">x</span>
          <span class="pnt" v-if="form.decor != '-9'" style="margin-left:10px" @click="existfield('decor')">v</span>
        </th>
        <th >
          <date-picker  v-if="form.shipDate != '-9' && form.shipDate != '-99'" input-class="form-control mx-input" @change="findOrders" format="DD.MM.YYYY"
                       placeholder="Ор. срок" style="margin-left: 2px;float:left;" v-model="form.shipDate" valueType="format"></date-picker>
          <span class="pnt" v-if="form.shipDate != '-99'" @click="nullfield('shipDate')">x</span>
          <span class="pnt" v-if="form.shipDate != '-9'" style="margin-left:10px" @click="existfield('shipDate')">v</span>
        </th>
        <th >
          <date-picker v-if="form.arriveDate != '-9' && form.arriveDate != '-99'" input-class="form-control mx-input" @change="findOrders" format="DD.MM.YYYY"
                       placeholder="Факт. срок" style="margin-left: 2px;float:left;" v-model="form.arriveDate" valueType="format"></date-picker>
          <span class="pnt" v-if="form.arriveDate != '-99'" @click="nullfield('arriveDate')">x</span>
          <span class="pnt" v-if="form.arriveDate != '-9'" style="margin-left:10px" @click="existfield('arriveDate')">v</span>
        </th>
        <th v-if="showField && type == 'analitic'">
        </th>
        <th v-if="showField && type == 'analitic'">
        </th>
        <th>
          <input v-if="form.dealerbill != '-9' && form.dealerbill != '-99'" class="form-control mx-input myinput" placeholder="Счет" style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.dealerbill">
          <span class="pnt" v-if="form.dealerbill != '-99'" @click="nullfield('dealerbill')">x</span>
          <span class="pnt" v-if="form.dealerbill != '-9'" style="margin-left:10px" @click="existfield('dealerbill')">v</span>
        </th>
        <th>
          <input v-if="form.dealerfullbill != '-9' && form.dealerfullbill != '-99'" class="form-control mx-input myinput" placeholder="Полный петли..." style="margin-left: 2px;float:left;" @change="findOrders" v-model="form.dealerfullbill">
          <span class="pnt" v-if="form.dealerfullbill != '-99'" @click="nullfield('dealerfullbill')">x</span>
          <span class="pnt" v-if="form.dealerfullbill != '-9'" style="margin-left:10px" @click="existfield('dealerfullbill')">v</span>
        </th>
        <th>
          <input v-if="form.managerComent != '-9' && form.managerComent != '-99'" class="form-control mx-input myinput" placeholder="Комментарий" style="margin-left: 2px;float:left" @change="findOrders" v-model="form.managerComent">
          <span class="pnt" v-if="form.managerComent != '-99'" @click="nullfield('managerComent')">x</span>
          <span class="pnt" v-if="form.managerComent != '-9'" style="margin-left:10px" @click="existfield('managerComent')">v</span>
        </th>

      </tr>
      <oneorder v-for="(order, index) in this.orders" :key="index" :showField="showField" :order="order" :type="type"></oneorder>
    </table>
  </section>
</template>

<script>
  import Acl from '@/utils/acl'
  import pagination from "@/utils/pagination";
  import oneorder from "./oneorder";
  import DatePicker from 'vue2-datepicker';
  import Vuex from 'vuex'
  import Http from "@/utils/http";

export default {
  mixins: [Acl],
  props: ['type'],
  data () {
    return {
      showField: false,
      isActiveCss:'',
      jsform: '{}',
      form: {
        id: null,
        claim_number: null,
        status: null,
        orderNum: null,
        zone: '',
        from: null,
        to: null,
        include: true,
        creator: null,
        sapnum: null,
        region: null,
        contain: null,
        decor: null,
        clientName: null,
        town: null,
        dealerbill: null,
        dealerfullbill: null,
        managerComent: null,
        clientAgreeDate: null,
        confectAgreeDate: null,
        shipDate: null,
        arriveDate: null,
        page:1,
        square: null,
        sumvalue: null,
        download:null,
        designer_id: null,
        saloonnum: null,
      }
    }
  },
  components:{
    oneorder: oneorder,
    DatePicker: DatePicker,
    pagination,
  },
  computed: {
    ...Vuex.mapGetters({
      apiUrl: 'app/apiUrl',
      orders: 'managerorderlist/datalist',
      pagination: 'managerorderlist/pagination'
    }),
    claimAvailable(){
      var available = true
      if (this.type == 'analitic'){
        return false
      }
      if (this.orders.length == 0) {
        return false
      }
      var id = null
      this.orders.map(e => {
        if (id == null) {
          id = e.ordernum
        }
        if (id != e.ordernum){
          available = false
          return
        }
      })
      return available
    }
  },
  methods: {
    ...Vuex.mapMutations({
      setError: 'app/setError',
    }),
    nullfield(name){
      this.form[name] == '-9' ? this.form[name] = null : this.form[name] = '-9'
      this.findOrders()
    },
    existfield(name){
      this.form[name] == '-99' ? this.form[name] = null : this.form[name] = '-99'
      this.findOrders()
    },
    findOrders() {
      this.$store.dispatch('managerorderlist/getOrders', this.form)
    },
    setPage(i) {
      this.form.page = i
      this.findOrders()
    },
    addClaim(){
      Http.post(this.apiUrl +   'ordersv2/addclaim', this.orders[0])
        .then(() => {
          this.findOrders()
        })
    },
    download(){
      var fm = Object.assign({}, this.form)
      fm.download = 1
      var url = Object.keys(fm).map((k) => {
        if (fm[k] != null){
          return encodeURIComponent(k) + '=' + encodeURIComponent(fm[k])
        }
      }).join('&')
      window.location.href= this.apiUrl + 'ordersv2/managerorderlist?type='+this.type+ "&"+ url
    },
    upload(){
      this.isActiveCss = 'disabled'
      this.setError(null)
      var formData = new FormData()
      formData.append('file', this.$refs.file.files[0])
      Http.post(this.apiUrl + '/ordersv2/addlist',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      ).then(() => {
        this.findOrders()
        this.isActiveCss = ''
        document.getElementById('file-upload').value = null
      }).catch((err) => {
        this.setError(err)
        this.isActiveCss = ''
        document.getElementById('file-upload').value = null
      })
    }
  },
  created() {
    this.$store.dispatch('managerorderlist/getOrders', this.form)
    this.$store.dispatch('managerorderlist/getManufactures')
  }
}
</script>
<style scoped>
  .myinput{
    height: 34px;
  }
  .mx-input {
    padding: 6px 6px;
  }
  th{
    text-align: center;
  }
  input[type="file"] {
    display: none;
  }
  .pnt{
    cursor:pointer;
  }
  .mx-datepicker {
    font-size: 10px;
    width: 120px;
  }
</style>
