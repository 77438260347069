<template>
  <div class="container">
    <Header />
    <div class="wrapper">
      <section id="step_1" class="clearfix">
        <div class="facade-container clearfix">
          <app-navbar :collections="collections"
                      @createOrder="addPosition"
                      @setFacadeBorder="setFacadeBorder"></app-navbar>
          <!-- /.sidebar -->
          <div class="img_container-loader" v-if="load">
            <img src="../../../assets/RehauLoader.gif" />
          </div>
          <div class="img_container"
          v-if="!load"
               :style="'background-image:url(' + img +');'">
            <img v-if="collection.id == 0" class="img_container-default" src="https://api.fronts.shop.rhsolutions.ru/images/background.png" alt="">
            <div class="facade-description-block clearfix">
              <h3 id="colname">{{ collection.name }}</h3>
              <p id="coldescription">{{ collection.descriptrtion }}</p>
            </div>
          </div>
        </div>
      </section>
      <app-second-step
        @addPosition="addPosition"
        :collections="collections"
        :types="types"
      ></app-second-step>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
  import Navbar from './navbar/navbar'
  import SecondStep from './second_step/index'
  import Footer from './footer/footer'
  import Http from '../../../utils/http'
  import Vuex from 'vuex'
  import Header from "@/components/Header.vue";

  export default {
    data() {
      return {
        facadeId: null,
        borderId: null,
        collections: [],
        imgConstruction: '',
        load: false,
        types: [],
        showDescription: false,
      }
    },
    components: {
      Header,
      appNavbar: Navbar,
      appSecondStep: SecondStep,
      appFooter: Footer,
    },
    computed: {
      ...Vuex.mapGetters({
        collection: 'index/collection',
        host: 'index/host',
        stepThree: 'index/stepThree',
        settings: 'app/settings'
      }),
      img: function() {
        const that = this;
        that.load = true;
        const imgServer = new Image();
        imgServer.onload = function () {
            that.load = false;
          }
        imgServer.src = this.collection.id != 0 ? this.host + this.collection.bigImgUrl : this.settings.background;
        return imgServer.src;
      }
    },
    methods: {
      ...Vuex.mapActions({
        addPos: 'index/addPosition'
      }),
      addPosition() {
        this.addPos(this.facadeId, this.borderId)
      },
      getCollections() {
        var that = this;
        Http.get(this.host + 'collectionv2/apilist')
          .then(function (response) {
            that.collections = response;
          })
          .catch(function (error) {
            console.log(error)
          });
      },
      getTypes() {
        var that = this;
        Http.get(this.host + 'typev2/listapi')
          .then(function (response) {
            that.types = response.data;
          })
          .catch(function (error) {
            console.log(error)
          });
      },
      setFacadeBorder(facadeId) {
        this.facadeId = facadeId
      },
    },
    watch: {
      orderId: function (val) {
        if (val != null) {
          this.$store.dispatch('index/getPositions', val)
        }
      }
    },
    mounted: function () {
      this.load = false;
      this.getCollections()
      this.getTypes()
      this.$store.commit('index/stepThree', false);
    },
    created() {
      console.log(this.$route.query)
      if (this.$route.query.order_id != null) {
        this.$store.commit('index/setOrderId', this.$route.query.order_id)
        this.$store.dispatch('index/getPositions')
        this.$store.dispatch('index/getSettings')
      }
    }
  }
</script>
<style>
  .img_container-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    float:left;
    left: 60%;
    height: 748px;
    width: fit-content;
  }

  .img_container-loader img {
    width: 60%;
  }

  .img_container-default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    float:left;
    bottom: 1px;
    height: 748px;
    width: 97%;
  }

  @media (max-width: 420px) {
    .img_container-loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position:relative;
      float:left;
      left: 35%;
      height: 748px;
      width: fit-content;
    }
      .color-block {
          overflow: scroll;
      }

    .img_container-loader img {
      width: 40%;
    }
  
    .img_container-default {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position:relative;
      float:left;
      bottom: 1px;
      height: 748px;
      width: 97%;
    }
  }
</style>
