<template>
  <section id="step_3" class="clearfix">
    <div class="container">
      <div class="parameter-block clearfix">
        <span>3</span>
        <h2>Состав заказа</h2>
        <p class="parameter-descr">Проверьте ваш заказ перед оформлением на производство</p>
      </div><!--/.parameter-block -->
      <p class="step3-title text-center">Ваш заказ</p>
    </div>
    <div class="container">
        <div class="buttons-step clearfix directions-wrapper">
            <div class="btn-two-wrapper" @click="leftScroll('left')" style="margin-left: 1%">
                <button class="btn btn-two directions" style="width: 15% !important;"></button>
                <p>Прокрутить влевло</p>
            </div>
            <div class="btn-two-wrapper"  @click="leftScroll('right')">
                <p>Прокрутить вправо</p>
                <button class="btn btn-one directions" style="width: 15% !important;"></button>
            </div>
        </div>
      <div class="table-responsive" ref="tableResp">
        <table class="table" id="ordertable">
          <thead>
          <tr>

            <td class="kormka_torets" style="width:100px;">Тип изделия</td>
            <td class="kormka_torets" style="width:100px;">Поверхность</td>
            <td class="kormka_torets">Торцевая кромка</td>
            <td class="kormka_torets">Текстуры</td>
            <td style="width:60px;">Высота</td>
            <td style="width:60px;">Ширина</td>

            <td>Присадки</td>
            <td style="width:100px;">Количество</td>
            <td>Цена</td>
            <td v-if="user.organisation != null">
              {{user.organisation.type_id == 10 ? 'Скидка' :'Наценка'}}
              <markdown
                  style="float:right"
                  :description="'если за числом добавить знак % то в процентах иначе в рублях'"
              ></markdown>
            </td>
            <td>Стоимость</td>
            <td class="edit-row"></td>
          </tr>
          </thead>
          <tbody v-for="position in positions" :key="position.position_id">
            <thirdposition @calcDiscount="getDiscount" :position="position"></thirdposition>
          </tbody>
        </table>
      </div>
      <div class="total-container clearfix">
        <div class="col-lg-3 col-sm-3">

        </div><!--/.col-lg-3 -->
        <div class="col-lg-3 col-sm-3">


        </div><!--/.col-lg-3 -->
        <div class="col-lg-3 col-sm-3">

        </div><!--/.col-lg-3 -->


        <div class="col-lg-3 col-sm-3">
          {{user.organisation !=null && user.organisation.type_id == 20 ? 'Наценка' :'Скидка'}} на заказ <input class="form-control" name="discount" :value="discount + '%'">
          <div class="total">Итого:&nbsp;<span class="total_amount" id="sumspan">{{sumAllPositions}}</span>&nbsp;<span class="rub">руб.</span>
          </div>
        </div>
      </div>
      <div class="buttons-step clearfix">
        <button type="button" @click="previous" class="prev-step2">Предыдущий шаг</button>
        <button type="submit"  @click="next" class="btn-order">Следующий шаг</button>
      </div>
      <div class="col-sm-6">
        <a :href="this.host + '/cartv2/print?id=' + $route.params.id" target="_blank"><div class="print_blank" style="margin-top:20px;">Распечатать бланк заказа для клиента</div></a>						</div><!--/.col-sm-6 -->
      <div class="col-sm-6">
        <a v-if="user.id != null" :href="this.host + '/cartv2/printdealer?id=' + $route.params.id" target="_blank"><div class="print_blank print_blank_diler" style="margin-top:20px;">Распечатать бланк заказа дилера</div></a>
      </div><!--/.col-sm-6 -->
    </div>
  </section>
</template>

<script>
  import Http from '../../../utils/http'
  import Vuex from 'vuex'
  import markdown from "@/utils/markdown";
  import thirdposition from "@/components/index/third_step/thirdposition";

  export default {
    data() {
      return {
        orderPositions: [],
        types: [],
        discount: 0,
          container: {}
      }
    },
    components: {
      Markdown: markdown,
      thirdposition: thirdposition
    },
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        positions: 'index/positions',
        sumAllPositions: 'index/sumAllPositions',
        user: 'auth/user'
      }),
    },
    methods: {
      ...Vuex.mapActions({
        check: 'auth/check'
      }),
      previous(){
        this.$router.push('/?id='+this.$route.params.id)
      },
      next(){
        this.$router.push('/delivery/'+this.$route.params.id)
      },
        leftScroll(direction) {
          if (direction === 'left') {
              this.container.scrollLeft -= 250;
          }
            if (direction === 'right') {
                this.container.scrollLeft += 250;
            }
        },
      getTypes() {
        Http.get(this.host + 'typev2/listapi')
          .then((response) => {
            this.types = response.data;
          })
      },
      getDiscount() {
        Http.post(this.host + 'ordersv2/getdiscount', {order_id: this.$route.params.id})
          .then((r) => {
            this.discount = r.data;
          })
      }
    },
    created() {
      this.check()
      if (this.$route.params.id != null) {
        this.$store.commit('index/setOrderId', this.$route.params.id)
        this.$store.dispatch('index/getPositions')
          .catch(() => {
            this.$router.push('/')
          })
        this.getDiscount()
      }
    },
    mounted: function () {
      this.getTypes()
      this.container = this.$refs.tableResp;

        this.container.style.overflowX = 'scroll';
        this.container.style.width = '100%';
        this.container.style.transitionTimingFunction = 'linear';
        this.container.style.transitionDelay = '300ms';
        this.container.style.transition = "position 2s linear 1s";
    }

  }
</script>
<style scoped>

  label.fl {
    display: block;
    cursor: pointer;
  }

  label.fl::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    content: url(http://s1.iconbird.com/ico/0612/GooglePlusInterfaceIcons/w18h181338911550clip.png);
  }

  label [type=file] {
    display: none;
  }

  .btn-one:after {
      position: absolute;
      content: url(https://api.fronts.shop.rhsolutions.ru/img_dev/arrow.png);
      top: 3px;
      left: 0;
      transform: rotate(270deg);
  }

  .btn-two:before {
      position: absolute;
      content: url(https://api.fronts.shop.rhsolutions.ru/img_dev/arrow.png);
      top: 2px;
      left: 0;
      transform: rotate(90deg);
  }

  .btn {
      box-shadow: none !important;
  }

  .detail-box {
    padding-left: 135px;
    padding-right: 8px;
  }

  .detail-box img {
    position: absolute;
    width: 130px;
    height: 81px;
    top: 0;
    left: 0;
    border: 1px solid #C7C7C7;
  }

  .directions-wrapper {
      display: none;
  }

  @media (max-width: 420px) {
      .directions {
          width: 25% !important;
          margin: 0;
          font-size: 10px;
          text-align: center;
      }

      .directions-wrapper {
          display: flex !important;
          flex-direction: row !important;
      }

      .col-sm-6 {
          display: flex;
          justify-content: flex-end;
      }

      .table-responsive {
          scroll-behavior: smooth;
          overflow-y: scroll;
          max-height: 38vh;
          min-height: 300px;
      }

      .add-detail, .print_blank {
          margin-left: 0px;
      }

      thead {
          position: sticky;
          top: -1px;
          border: 1px solid #ddd;
          background-color: white;
          z-index: 999;
      }
    .btn-two-wrapper {
        display: flex;
        justify-content: center;
    }

      .btn-two-wrapper button {
        height: fit-content;
    }

      .btn-two-wrapper .btn {
          padding: 0;
      }

      .btn-two-wrapper p {
          width: 135px;
      }

    #step_3 .buttons-step {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    #step_3 .buttons-step > button {
      width: 80%;
    }
  }
</style>
