<template>
  <section>
    <div class="clearfix_container-loader" v-if="load">
      <img src="../../assets/RehauLoader.gif" />
    </div>
      <div class="form-row">
        <div class="col-xs-1">
          <button class="btn btn-primary" @click="saveData">Добавить</button>
        </div>
        <div class="col-xs-6">
          <pagination class="pull-right" @setPage="setPage" :padding="2" :totalpages="pagination.total/pagination.limit" :page="pagination.page"></pagination>
        </div>
      </div>
      <table class="table table-striped table-bordered table-hover">
        <tr>
          <th>Название</th>
          <th>Заголовок</th>
          <th>Описание</th>
          <th>Количество р/дней на подготовку</th>
          <th>Смена текстур</th>
          <th>Картинка</th>
          <th>Большая картинка</th>
          <th>Сортировка</th>
          <th></th>
          <th></th>
        </tr>
        <tbody v-for="model in datalist" :key="model.collection_id">
          <tr v-if="edit == null || edit.collection_id != model.collection_id">
            <td>{{model.name}}</td>
            <td>{{model.prehead}}</td>
            <td width="200px;">{{model.description}}</td>
            <td>{{model.preparation}}</td>
            <td>{{model.isvector == 1 ? 'Да' : 'Нет'}}</td>
            <td style="text-align: center">
              <button v-if="model.filepath == null || model.filepath == ''" class="btn btn-primary" @click="() => showModalCollecion('filepath', model.collection_id)">Загрузить</button>
              <section v-if="loadCollectioIDFile === model.collection_id">
                <div class="modal">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div class="modal-title">Внимание!</div>
                        <div class="modal-body_title">Рекомендуемые размеры фото - 300x220 px</div>
                        <label :for="'upload-filepath' + model.collection_id">
                          <span class="btn btn-primary" style="cursor: pointer;">Загрузить фото</span>
                        </label>
                        <input 
                          :id="'upload-filepath' + model.collection_id" 
                          type="file" 
                          name="f" 
                          class="form-control " 
                          :ref="'filepath'+model.collection_id" 
                          accept=".png" 
                          @input="uploadImg('filepath', model.collection_id)"
                        >
                      </div>
                      <div class="modal-footer">
                        <a href="" id="cancel"  @click.stop.prevent="closeModalCollection" class="btn btn-secondary">Закрыть</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <img
                v-if="model.filepath != null && model.filepath != ''"
                style="min-width: 200px; width: 200px; min-height: 20px; background-color: #cfcfcf; cursor: pointer"
                :src="encodeURI(apiUrl +  model.filepath )"
                alt=""
                @click="() => showModalCollecion('filepath', model.collection_id)"
              >
            </td>
            <td style="text-align: center">
              <button v-if="model.bgfilepath == null || model.bgfilepath == ''" class="btn btn-primary" @click="() => showModalCollecion('bgfilepath', model.collection_id)">Загрузить</button>
              <section v-if="loadCollectioID === model.collection_id">
                <div class="modal">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div class="modal-title">Внимание!</div>
                        <div class="modal-body_title">Рекомендуемые размеры фото - 600x400 px</div>
                        <label :for="'upload-bgfilepath' + model.collection_id">
                          <span class="btn btn-primary" style="cursor: pointer">Загрузить фото</span>
                        </label>
                        <input
                          accept=".png"
                          :id="'upload-bgfilepath' + model.collection_id"
                          type="file"
                          name="f"
                          class="form-control "
                          :ref="'bgfilepath'+model.collection_id"
                          @input="uploadImg('bgfilepath', model.collection_id)"
                        >
                      </div>
                      <div class="modal-footer">
                        <a href="" id="cancel"  @click.stop.prevent="closeModalCollection" class="btn btn-secondary">Закрыть</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <img 
                v-if="model.bgfilepath != null && model.bgfilepath != ''"
                style="min-width: 200px; width: 200px; min-height: 20px; background-color: #cfcfcf; cursor: pointer"
                :src="encodeURI(apiUrl +  model.bgfilepath )"
                alt=""
                @click="() => showModalCollecion('bgfilepath', model.collection_id)"
              >
            </td>
            <td>
              <span style="cursor: pointer" class="glyphicon glyphicon-arrow-up" @click="up(model.collection_id)"></span>
              <span style="cursor: pointer" class="glyphicon glyphicon-arrow-down" @click="down(model.collection_id)"></span>
            </td>
            <td><a style="cursor: pointer" class="glyphicon glyphicon-pencil" @click="toChange(model)" ></a></td>
            <td width="100px;"><vue-confirmation-button
              style="cursor: pointer"
                    :css="'glyphicon glyphicon-trash'"
                    v-on:confirmation-success="deleteData(model.collection_id)">
            </vue-confirmation-button></td>
          </tr>
          <tr v-if="edit != null && edit.collection_id == model.collection_id">
            <td><input type="text" class="form-control" v-model="edit.name" placeholder="Название"></td>
            <td><input type="text" class="form-control" v-model="edit.prehead" placeholder="заголовок"></td>
            <td><input type="text" class="form-control" v-model="edit.description" placeholder="Описание"></td>
            <td><input type="text" class="form-control" v-model="edit.preparation" placeholder="Срок"></td>
            <td>
              <select class="form-control" v-model="edit.isvector">
                <option value="0">Нет</option>
                <option value="1">Да</option>
              </select>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td><a class="glyphicon glyphicon-ok" @click="update"></a></td>
            <td ><a class="glyphicon glyphicon-remove" @click="edit = null"></a></td>
          </tr>
        </tbody>
      </table>
      <ModalFile 
        @closeAddForm="closeAddForm"
        :isVisible="addModalShow"
      />
  </section>
</template>

<script>
import ModalFile from '../admin/UI/ModalFile.vue'
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import vueConfirmationButton from '../../utils/confirmButton'
  import pagination from "../../utils/pagination"
  import Http from '@/utils/http'

  export default {
    mixins: [Acl],
    name: 'type',
    data () {
      return {
        addModalShowCollection: false,
        addModalShow: false,
        loadCollectioID: null,
        loadCollectioIDFile: null,
        load: false,
        form:{
          name: 'Новый',
        },
        page: null,
        edit: null,
      }
    },
    components: {
      ModalFile:ModalFile,
      VueConfirmationButton: vueConfirmationButton,
      pagination
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        datalist: 'collection/datalist',
        pagination: 'collection/pagination',
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader:'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        save: 'collection/save',
        find: 'collection/find',
        drop: 'collection/delete'
      }),
      saveData(){
        const that = this;
        that.load = true;
        this.setError(null)
        this.save(this.form)
                .then(() =>{
                  this.findData()
                  that.load = false;
                })
      },
      closeModalCollection () {
        this.loadCollectioIDFile = null;
        this.loadCollectioID = null;
      },
      closeAddForm() {
        this.addModalShow = false
      },
      update() {
        this.setError(null)
        this.save(this.edit)
                .then(() =>{
                  this.edit = null
                  this.findData()
                })
      },
      deleteData(id){
        const that = this;
        that.load = true;
        this.setError(null)
        this.drop(id)
                .then(() =>{
                  this.findData()
                  that.load = false;
                })
      },
      showModalCollecion(type, id) {
        if(type === 'filepath') {
          this.loadCollectioIDFile = id
        } else {
          this.loadCollectioID = id
        }
      },
      toChange(model){
        this.edit = Object.assign({}, model)
      },
      setPage(i){
        this.page = i
        this.findData()
      },
      findData(){
        this.find({page:this.page})
      },
      up(id){
        Http.get(this.apiUrl + '/collectionv2/up?id=' + id)
          .then(() => {
            this.findData()
          })
      },
      down(id){
        Http.get(this.apiUrl + '/collectionv2/down?id=' + id)
          .then(() => {
            this.findData()
          })
      },
      uploadImg(type, id){
        this.loadCollectioIDFile = null;
        this.loadCollectioID = null;
        const that = this;
        that.load = true;
        var formData = new FormData()
        var file = this.$refs[type + id]
        formData.append('img', file[0]['files'][0])
        formData.append('collection_id', id)
        formData.append('type', type)
        const fileSize = file[0]['files'][0];
        if (fileSize && fileSize.size > 512000) {
          that.load = false;
          this.addModalShow = true;
          return;
        }
        Http.post(this.apiUrl + '/collectionv2/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        ).then(() => {
          this.findData()
          document.getElementById('upload-'+type + id).value = null
          that.load = false;
        }).catch((err) => {
          this.setError(err)
          that.load = false;
        })
      },
    },
    created() {
      this.setHeader('Коллекции')
      this.page = this.pagination.page
      this.findData()
    }
  }
</script>
<style scoped>
  input[type="file"] {
    display: none;
  }
  th {
    text-align: center;
  }

  td {
    width: fit-content;
  }

  td img {
    height: 150px;
  }

  .file__span {
    display: inline-block;
    background: #e4e7ea;
    border: none;
    color: black;
    height: 100%;
    font-size: 14px;
    line-height: 16px;
    outline: none;
    padding: 12px 10px;
    transition: .25s ease-out;
    border-radius: 6px;
  }

  .file__span:hover {
    background: #4FC7B5;
    color: white;
    border-color: #4FC7B5;
    border-radius: 6px;
    word-spacing: -0.5px;
  }

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .clearfix_container-loader {
    align-items: center;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .07);
    display: block;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: all .3s linear;
    width: 100vw;
    z-index: 2500;
  }
  .clearfix_container-loader img {
    width: 12%;
  }

  .modal {
    align-items: center;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .07);
    display: block;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: all .3s linear;
    width: 100vw;
    z-index: 2500;
  }
  .modal-body_title {
    text-align: center;
    color: #D03D72;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
</style>
