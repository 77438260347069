<template>
  <section  class="clearfix">
    <div class="container">
        <div class="clear"></div>
        <div class="clear" style="height:5px;"></div>
        <div class="col-sm-5">
          <div class="form-group contact-block">
          </div>
        </div>
        <div class="clear" style="height:10px;"></div>
        <div class="col-lg-12">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-comment">
              <label class="control-label active" >Комментарий</label>
              <textarea class="form-control" rows="1" v-model="comment" placeholder="Комментарий" @change="updateOrder" style="width:100%;height:43px;"></textarea>
              <div class="help-block"></div>
            </div>
          </div>
          
        </div>
        <div class="clear" style="height:20px;"></div>
        <div class="col-sm-12">
          <div class="col-sm-2">
            <div class="form-group field-orders-dpackage">
              <input type="hidden" :value="greatPack"><label>
              <input type="checkbox" class="checkbox" @change="updateOrder" v-model="greatPack">
              <markdown
                :label=" ' Улучшенная упаковка'"
                :description="' (П-образный профиль по периметру фасадов), ' + settings.greatpack + ' руб. / м2. \n'+
                  'Настоятельно рекомендуем заказывать фасады с улучшенной упаковкой.'"
                :hiddenStyle="'-30px 100px'"
                :labelStyle="'margin-left:5px;'"
              ></markdown>
              <div class="clear"></div>
            </label>
            </div>
          </div>
          <div class="col-sm-7">
          </div>
        </div>
        <div class="col-sm-6"><div class="buttons-step clearfix">
          <a @click="$router.push('/cart/' + orderId)"><button type="button" class="prev-step2">Предыдущий шаг</button></a>
        </div>
        </div>
      <div class="col-sm-6">
        <h4 v-if="!greatPack" class="text text-right" style="color:#D03D72;">
        При отсутствии улучшенной упаковки возникает риск повреждения при транспортировке,
        за который компания РЕХАУ ответственности не несет
        </h4>
      </div>
      <div class="col-sm-12">
        <h3>Выберите дилера РЕХАУ для отправки заказа, сроки и условия доставки согласовываются
          дополнительно с дилером.</h3>
      </div>

      <div class="col-sm-12 hw" v-if="canAccept">

        <div class="col-xs-12"
             @click="$router.push('/finish/' + order.order_id  + '?dealer_id=' +wh.id + '&amp;money=' + '&amp;saloonnum=' + user.organisation.saloonnum)"
             v-for="wh in user.organisation.linked" :key="wh.id">
          <div class="col-xs-3 h4">
            {{ wh.name }}
          </div>
          <div class="col-xs-6 h4 text-center">
            {{ wh.adress }}
          </div>
          <div class="col-xs-3 h4 text-right" style="color:white">
            Отправить заказ
          </div>
        </div>
      </div>
      </div>
  </section>
</template>
<script>
  import Markdown from "../../../utils/markdown";
  import Vuex from 'vuex'

  export default {
    components:{
      Markdown:Markdown
    },
    data(){
      return {
        comment: null,
        hardPack: false,
        greatPack: false,
      }
    },
    methods: {
      ...Vuex.mapActions({
        finddealers: 'dealers/find',
        findwarehouses: 'warehouses/find',
        findorder: 'order/get',
        updateorder: 'order/update'
      }),
      ...Vuex.mapMutations({
        setdealers: 'dealers/set'
      }),
      findSaloons() {
        this.finddealers({
          query: this.saloonnum,
          type_id: 20,
          limit: 5,
        })
      },
      getInfoByOrderId(){
        this.greatPack = this.order.dpackage
        this.comment = this.order.comment
      },
      updateOrder() {
        var newOrd = Object.assign({}, this.order)
        newOrd.dpackage = this.greatPack
        newOrd.comment = this.comment
        this.updateorder(newOrd)
      },
    },
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        positions: 'index/positions',
        sumAllPositions: 'index/sumAllPositions',
        user: 'auth/user',
        points: 'index/points',
        itemTowns: 'index/itemTowns',
        saloons: 'dealers/datalist',
        settings: 'app/settings',
        warehouses: 'warehouses/datalist',
        order: 'order/order',
      }),
      items(){
        return this.itemTowns
      },
      dealers(){
        var points = this.points
        if (this.sort == 2) {
          return points.sort(function(a, b){
            return a.money - b.money
          })
        } else {
          return points.sort(function (a, b) {
            return a.days - b.days
          })
        }
      },
      canAccept(){
        if (this.canEmail == false){
          return false
        }
        if (this.canName == false){
          return false
        }
        if (this.canPhone == false){
          return false
        }
        if (this.canSurname == false){
          return false
        }
        return true
      },
      canPhone(){
        if (this.order.phone == null || this.order.phone == '') {
          return false
        }
        return true
      },
      canSurname(){
        if (this.order.surname == null || this.order.surname == '') {
          return false
        }
        return true
      },
      canName(){
        if (this.order.name == null || this.order.name == '') {
          return false
        }
        return true
      },
      canEmail(){
        if (this.order.email == null || this.order.email == '') {
          return false
        }
        return true
      },
    },
    created(){
      if (+this.$route.params.id != null) {
        this.orderId = +this.$route.params.id
      }
      this.getInfoByOrderId()
    },
    mounted: function() {
      this.findwarehouses()
    }
  }

</script>
<style>
  .dinamicSelect{
    position: absolute;
    z-index: 2;
    background-color: white;
  }

  .dinamicSelect :hover{
   background-color: #4FC7B5;
   color: white;
  }

  .hw :hover{
    background-color: #4FC7B5;
    color: white;
  }
</style>
