<template>
  <section v-if="isVisible">
    <div class="modal" @click.prevent="close">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-title">Повторите попытку!</div>
            <div class="modal-body_title">Размер файла не должен превышать 500Кб.</div>
            <div class="modal-body_text">(Рекомендуемый формат файла - .png)</div>
          </div>
          <div class="modal-footer">
            <a href="" id="cancel"  @click.prevent="close" class="btn btn-secondary">Закрыть</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    props: ['isVisible', 'closeAddForm'],
    methods: {
      close () {
        this.$emit('closeAddForm')
      },
    }
  }
</script>
<style scoped>
  .modal {
    align-items: center;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .07);
    display: block;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: all .3s linear;
    width: 100vw;
    z-index: 2500;
  }

  .modal-body_title {
    text-align: center;
    color: #D03D72;
  }

  .modal-body_text {
    text-align: center;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
</style>
