<template>
  <section v-if="orderId > 0" id="step_2">
    <div class="container-fluid">
      <div id="orderPjax">
        <div class="two_step_title clearfix">
          <div class="col-lg-5 col-md-6 col-sm-7">
            <div class="row">
              <div class="parameter-block clearfix">
                <span>2</span>
                <h2>Параметры деталей</h2>
                <p class="parameter-descr">Выберите необходимые детали и укажите их параметры</p>
              </div><!--/.parameter-block -->
            </div><!--/.row -->
          </div><!--/.col-lg-5 -->
          <div class="col-lg-5 col-md-3 col-sm-1">
            <div class="row">
              <div class="line-gray"></div>
            </div><!--/.row -->
          </div><!--/.col-lg-5 -->
          <div class="col-lg-2 col-md-3 col-sm-4 two_step-order">
            <div class="row">
              <div class="ordering clearfix">
                <span>3</span>
                <h4>Оформление заказа</h4>
              </div><!--/.ordering -->
            </div><!--/.row -->
          </div><!--/.col-lg-2 -->
        </div><!-- /.two_step_title -->
        <h4 class="text text-right" style="color:#4FC7B5;">* Все цены являются оптовыми и указаны с учетом НДС при отгрузке со склада РЕХАУ. Рекомендованные розничные цены выше на 30%</h4>

        <a class="delete-all" @click.prevent="deletePositions">Удалить все детали</a>					</div>
      <div class="detail-container clearfix">
        <app-positions :types="types"
                :collections="collections"
        ></app-positions>
      </div>
      <div class="summ-container clearfix">
        <div class="col-sm-2">
          <a id="adddetail" class="add-detail" @click.prevent="addPosition">Добавить деталь</a>
        </div><!--/.col-lg-2 -->
        <div class="col-sm-2">
          <a class="delete-all" @click.prevent="deletePositions">Удалить все детали</a>
        </div><!--/.col-lg-2 -->
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <div class="total">Сумма:&nbsp;<span class="total-summ">{{summ}}</span>&nbsp;<span class="rub">руб.</span></div>
        </div>
      </div>
      <div id="button-mob" class="buttons-step clearfix">
        <button type="button" class="prev-step">Предыдущий шаг</button>
        <form >
          <button id="nextStepclick" type="submit" class="next-step" @click.prevent="nextStep"
                  :style="createButtonStyle"
          >Следующий шаг</button>
        </form>
      </div><!-- /.container-fluid -->
    </div></section>
</template>
<script>
  import Positions from '../../positions'
  import Vuex from 'vuex'

  export default {
    props: ['collections', 'types'],
    data() {
      return{
      }
    },
    computed: {
      ...Vuex.mapGetters({
        orderId: 'index/orderId',
        sumAllPositions: 'index/sumAllPositions',
        existNullSum: 'index/existNullSum',
        positions:'index/positions'
      }),
      summ(){
        var res = " " + this.sumAllPositions
        if (res > 0) {
          return res.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
        }
        
        return 0
      },
      createButtonStyle() {
        if (this.existNullSum === false && this.validatePositions()===true) {
          return 'position:relative;display:inline-block;margin-top:29px;margin-left:30px;height:40px;'
        } else {
          return 'position:relative;background-color:#d9d9d9;display:inline-block;margin-top:29px;margin-left:30px;height:40px;'
        }
      },
    },
    components: {
      AppPositions: Positions,
    },
    methods: {
      ...Vuex.mapActions({
        deletePositions: 'index/deletePositions',
      }),
      addPosition() {
        this.$emit('addPosition')
      },
      validatePositions(){
        var result = true
        this.positions.map(p => {
          if (p.looptype > 0 && p.loopcount < 1){
            result = false
          }
          this.types.map(ty => {
            if (ty.handle > 0 && ty.type_id == p.type_id && p.handle_type < 1){
              result = false
            }
          })
        })
        return result
      },

      nextStep(){
        if (this.orderId > 0 && this.validatePositions()) {
          if (this.existNullSum === false) {
            this.$router.push('/cart/' + this.orderId)
          }
        }
      },
        onKeyDown(e) {
          if (e.keyCode === 187) {
            this.addPosition()
          }
          if (e.keyCode === 39) {
            this.$store.commit('nextBlure')
          }
          if (e.keyCode === 37) {
            this.$store.commit('previosBlure')
          }

        }
    },
      created() {
          document.addEventListener('keydown', this.onKeyDown)
      }
  }
</script>
<style>
  #step_2{
    display:block;
  }
  @media (max-width: 420px) {

    #step_2 .detail-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 9px 0px;
    }

    #step_2 .detail-delete, .detail-copy {
      padding-left: 20px;
      margin: 0px 10px;
    }

    #mob-row {
      display: flex;
      flex-direction: column;
    }

    #mob-row .select-detail input[type="text"].form-control {
      width: 20%;
    }

    #mob-row .select-detail {
      display: flex;
      justify-content: space-between;
      max-height: 10px;
      margin-bottom: 40px;
    }

    #mob-row .select-detail > .form-group {
      display: flex;
      width: 200px;
      justify-content: flex-end;
    }

    .comment-mob {
      width: 300px !important;
    }

    #step_2 .form-group {
      margin-bottom: 50px;
    }

    .detail-delete {
      float: left;
    }

    #step_2 .summ-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    #button-mob .buttons-step {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #step_2 .buttons-step button {
      width: 100%;
      margin: 0px;
    }

    #step_2 .buttons-step form {
      width: 100%;
    }

    #step_2 .buttons-step form button {
      width: 100%;
    }

    #step_2 .section .col-sm-5:last-of-type, .col-sm-7:last-of-type {
        height: 1em;
    }

    #step_2 .add-info {
      margin-top: 10px;
      width: 100%;
    }

    .mob-form-detail .label-head {
      display: flex;
      align-items: center;
    }

    .mob-form-detail {
      display: flex;
      justify-content: space-between;
      max-height: 10%;
      max-height: 60px;
      margin-bottom: 20px !important;
    }

    #step_2 .footnote {
      margin-left: 15px !important;
    }
  }
</style>
