<template>
  <section>
      <div class="form-row">
        <div class="col-xs-1">
          <button class="btn btn-primary" @click="saveData">Добавить</button>
        </div>
        <div class="col-xs-7"></div>
        <div class="col-xs-4">
          <pagination class="pull-right" @setPage="setPage" :padding="2" :totalpages="pagination.total/pagination.limit" :page="pagination.page"></pagination>
        </div>
      </div>
      <table class="table table-bordered table-hover">
        <tr>
          <th>Название</th>
          <th>Описание</th>
          <th>Ручка</th>
          <th>Картинка</th>
          <th></th>
          <th></th>
        </tr>
        <tbody v-for="model in datalist" :key="model.type_id">
          <tr v-if="edit == null || edit.type_id != model.type_id">
            <td>{{model.name}}</td>
            <td>{{model.description}}</td>
            <td>{{model.handle}}</td>
            <td>
              <label :for="'file-upload' + model.type_id">
                <img v-if="model.filename != null" width="200px" :src="encodeURI(apiUrl + 'images/types/' +model.filename )" alt="">
                <span v-if="model.filename == null || model.filename == ''">Загрузить</span>
              </label>
              <input :id="'file-upload' + model.type_id" type="file" name="f" class="form-control " :ref="'img'+model.type_id" @change="uploadImg(model.type_id)">
              <span v-if="model.filename != null && model.filename != ''" class="glyphicon glyphicon-trash" @click="nullImg(model.type_id)"></span>
            </td>
            <td><span class="glyphicon glyphicon-eye-open" @click="toLinks(model.type_id)"></span></td>
            <td><a class="glyphicon glyphicon-pencil" @click="toChange(model)" ></a></td>
            <td width="100px;"><vue-confirmation-button
                    :css="'glyphicon glyphicon-trash'"
                    v-on:confirmation-success="deleteData(model.type_id)">
            </vue-confirmation-button></td>
          </tr>
          <tr v-if="edit != null && edit.type_id == model.type_id">
            <td><input type="text" class="form-control" v-model="edit.name" placeholder="Название"></td>
            <td><input type="text" class="form-control" v-model="edit.description" placeholder="Описание"></td>
            <td><input type="text" class="form-control" v-model="edit.handle" placeholder="Ручка"></td>
            <td></td>
            <td></td>
            <td><a class="glyphicon glyphicon-ok" @click="update"></a></td>
            <td ><a class="glyphicon glyphicon-remove" @click="edit = null"></a></td>
          </tr>
        </tbody>
      </table>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import vueConfirmationButton from '../../utils/confirmButton'
  import pagination from "../../utils/pagination"
  import Http from "@/utils/http"
  export default {
    mixins: [Acl],
    name: 'type',
    data () {
      return {
        form:{
          name: 'Новый',
        },
        page: null,
        edit: null,
      }
    },
    components: {
      VueConfirmationButton: vueConfirmationButton,
      pagination
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        datalist: 'type/datalist',
        pagination: 'type/pagination',
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader:'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        save: 'type/save',
        find: 'type/find',
        drop: 'type/delete'
      }),
      toLinks(id) {
        this.$router.push('/type/' + id)
      },
      saveData(){
        this.setError(null)
        this.save(this.form)
                .then(() =>{
                  this.findData()
                })
      },
      update() {
        this.setError(null)
        this.save(this.edit)
                .then(() =>{
                  this.edit = null
                  this.findData()
                })
      },
      deleteData(id){
        this.setError(null)
        this.drop(id)
                .then(() =>{
                  this.findData()
                })
      },
      toChange(model){
        this.edit = Object.assign({}, model)
      },
      setPage(i){
        this.page = i
        this.findData()
      },
      findData(){
        this.find({page:this.page})
      },
      uploadImg(id){
        var formData = new FormData()
        var file = this.$refs['img' + id]
        formData.append('img', file[0]['files'][0])
        formData.append('type_id', id)
        Http.post(this.apiUrl + '/typev2/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        ).then(() => {
          this.findData()
          document.getElementById('file-upload' + id).value = null
        }).catch((err) => {
          this.setError(err)
        })
      },
      nullImg(id){
        var formData = new FormData()
        formData.append('type_id', id)
        Http.post(this.apiUrl + '/typev2/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        ).then(() => {
          this.findData()
          document.getElementById('file-upload' + id).value = null
        }).catch((err) => {
          this.setError(err)
        })
      }

    },
    created() {
      this.setHeader('Типы изделий')
      this.page = this.pagination.page
      this.findData()
    }
  }
</script>
<style>
  input[type="file"] {
    display: none;
  }
</style>
