<template>
  <tr >
    <td>{{thirdposition.typename}}</td>
    <td>{{thirdposition.collection_name}} {{thirdposition.design}} {{thirdposition.colorcode}}</td>
    <td>{{thirdposition.bordername}}</td>
    <td>{{getTextures()}}</td>
    <td><input type="text" class="form-control" v-model="thirdposition.lenghtsize" @change="update"></td>
    <td><input type="text" class="form-control" v-model="thirdposition.widthsize" @change="update"></td>
    <td>
      <select v-model="thirdposition.looptype"
              class="form-control form-step2 posinput selectpicker slate "
              style="width:50%;float:left"
        >
        <option value="0">Присадки</option>
        <option value="1">Слева</option>
        <option value="2">Справа</option>
        <option value="3">Сверху</option>
        <option value="4">Снизу</option>
      </select>
      <input type="text" class="form-control" @change="update" style="width:40%;float:left;margin-left:5%"
             v-model="thirdposition.loopcount" >
    </td>
    <td><input type="text" class="form-control" v-model="thirdposition.amount" @change="update"></td>
    <td>{{thirdposition.realcalcprice}}</td>
    <td v-if="user.organisation != null">
      <input type="text" class="form-control" v-model="thirdposition.discount" @change="update">
    </td>
    <td>{{thirdposition.sumvalue}}</td>
  </tr>
</template>

<script>
  import Http from '../../../utils/http'
  import Vuex from 'vuex'

  export default {
    data() {
      return {
        thirdposition: {},
        types: [],
      }
    },
    props:['position'],
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        user: 'auth/user'
      }),
    },
    watch:{
      position(){
        this.thirdposition = Object.assign({}, this.position)
      }
    },
    methods: {
      update() {
        if (!this.validate()) {
          this.thirdposition = Object.assign({}, this.position)
          return
        }
        Http.post(this.host + 'ordersv2/updateposition', this.thirdposition)
          .then(() => {
            this.$store.dispatch('index/getPositions')
            this.$emit('calcDiscount')
          })
          .catch(() => {
            this.thirdposition = Object.assign({}, this.position)
          })
      },
      validate() {
        if (+this.thirdposition.lenghtsize > +this.position.maxlenght) {
          return false
        }
        if (+this.thirdposition.widthsize > +this.position.maxwidth) {
          return false
        }
        if (+this.thirdposition.amount <= 0) {
          return false
        }
        if (+this.thirdposition.looptype > 0 && +this.thirdposition.loopcount <= 0) {
          return false
        }
        return true
      },
      getTextures(){
        if (!(this.thirdposition.isvector > 0)) {
          return  'Без текстуры'
        }
        if (this.thirdposition.textures == 10) {
          return  'Горизонтальная'
        }
        if (this.thirdposition.textures == 20) {
          return  'Вертикальная'
        }
        return 'Не выбрано'
      },
      getTypes() {
        var that = this;
        Http.get(this.host + 'typev2/listapi')
          .then(function (response) {
            that.types = response.data;
          })
      },
    },
    created() {
       Object.assign(this.thirdposition, this.position)
    },
    mounted: function () {
      this.getTypes()
    }
  }
</script>
<style scoped>
  label.fl {
    display: block;
    cursor: pointer;
  }

  label.fl::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    content: url(http://s1.iconbird.com/ico/0612/GooglePlusInterfaceIcons/w18h181338911550clip.png);
  }

  label [type=file] {
    display: none;
  }

  .detail-box {
    padding-left: 135px;
    padding-right: 8px;
  }

  .detail-box img {
    position: absolute;
    width: 130px;
    height: 81px;
    top: 0;
    left: 0;
    border: 1px solid #C7C7C7;
  }
</style>
