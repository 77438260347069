<template>
  <footer style="display: block;">
    <div class="footer clearfix">
      <div>
        <a href="https://www.rehau.com/ru-ru/policy" target="_blank">Политика конфиденциальности</a>
        <a href="fronts@rhsolutions.ru">fronts@rhsolutions.ru</a>
        РЕХАУ © {{currentYear}}
        <!-- <a @click = "login" v-if="user.username != null" style="cursor: pointer">{{user.username}}</a>
        <a @click = "login" v-if="user.organisation != null" style="cursor: pointer">{{user.organisation.name}}</a>
        <a @click = "login" v-if="user.username == null" style="cursor: pointer">Вход для дилера </a> -->
      </div>
    </div><!-- /.footer -->
  </footer>
</template>
<script>
  import Vuex from "vuex";

  export default {
    data() {
      return {
      }
    },
    computed: {
      ...Vuex.mapGetters({
        user: 'auth/user'
      }),

      currentYear() {
        return new Date().getFullYear()
      }
    },
    methods: {
      login() {
        this.$router.push("/order")
      },
      ...Vuex.mapActions({
        check: 'auth/check'
      }),
    },
    created() {
      this.check()
    }
  }
</script>
<style scoped>
@media (max-width: 420px) {
    .footer {
        margin-top: 60px;
        text-align: center;
        justify-content: center;
    }
}
@media (max-width: 420px) and (min-width: 360px) {
  .footer {
    text-align: center;
    justify-content: center;
      margin-top: 0px;
  }
}
</style>