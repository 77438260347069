<template>
  <section :class='isActiveCss'>
      <div class="form-row">
        <div class="col-xs-7">
          <div class="col-xs-1">
            <input class="form-control" @keyup="findData" @change="findData" v-model="form.number" type="text" placeholder="Номер / Id дизайнера">
          </div>
          <div class="col-xs-2">
            <select  class="form-control" @change="findData" v-model="form.state">
              <option value="">Статус</option>
              <option value="10">Отправлен</option>
              <option value="20">Не отправлен</option>
            </select>
          </div>
          <div class="col-xs-3" v-if="user.organisation == null">
            <select  class="form-control" @change="findData" v-model="form.organisation">
              <option value="">Организация</option>
              <option v-for="dealer in dealers" :key="dealer.id" :value="dealer.id">{{dealer.name}}</option>
            </select>
          </div>
          <div class="col-xs-2">
            <input class="form-control" @keyup="findData" @change="findData" v-model="form.customer" type="text" placeholder="Заказчик">
          </div>
          <div class="col-xs-2">
            <input class="form-control" @keyup="findData" @change="findData" v-model="form.email" type="text" placeholder="Еmail">
          </div>
          <div class="col-xs-2">
            <input class="form-control" @keyup="findData" @change="findData" v-model="form.phone" type="text" placeholder="Телефон">
          </div>
        </div>
        <div class="col-xs-1">
          <a class="btn btn-primary" style="margin-top: 0px;" @click.prevent="download">Скачать</a>
        </div>
        <div class="col-xs-4">
          <pagination class="pull-right" style="margin-top: 0px;" @setPage="setPage" :padding="2" :totalpages="pagination.total/pagination.limit" :page="pagination.page"></pagination>
        </div>
      </div>
      <table class="table table-striped table-bordered table-hover">
        <tr>
          <th>Заказ</th>
          <th>Номер салона</th> 
          <th>ID дизайнера</th>
          <th>Статус</th>
          <th>Дата</th>
          <th>Сумма</th>
          <th>Способ доставки</th>
          <th>Сумма доставки</th>
          <th>Организация</th>
          <th>Заказчик</th>
          <th>Email</th>
          <th>Телефон</th>
          <th>№ счета дилеру</th>
          <th>Комментарии к заказу</th>
          <th>Ориентировочный срок поставки в Гжель</th>
          <th></th>
          <th>Печать</th>
          <th>Хls</th>
          <th>Вложения</th>
          <th>Xml</th>
          <th>Xml2</th>
          <th>Copy</th>
        </tr>
        <tbody v-for="model in datalist" :key="model.order_id">
          <tr>
            <td @click="toOrder(model)">{{model.order_id}}</td>
            <td>{{model.saloonnum}}</td> 
            <td @click="setEdit(model)">
              <input v-if="model.order_id == edit.order_id" v-model="edit.designer_id"
                     class="form-control" type="text" @change="update(edit)">
              <span v-if="model.order_id != edit.order_id">{{model.designer_id}}</span>
            </td>
            <td>{{model.delivery_diler >0 ? 'Отправлен' : ' Не отправлен'}}</td>
            <td>{{new Intl.DateTimeFormat('ru-RU').format(new Date(model.order_date))}}</td>
            <td >{{model.sumvalue}}</td>
            <td>{{model.isDealer == 1 ? 'С поммощью ПЭК' :'Со склада РЕХАУ'}}</td>
            <td>{{model.money}}</td>
            <td>{{getDealer(model.organization_id).name}}</td>
            <td>{{model.surname}} {{model.name}}</td>
            <td>{{model.email}}</td>
            <td>{{model.phone}}</td>
            <td>{{model.dealer_bill}}</td>
            <td @click="setEdit(model)">
              <input v-if="model.order_id == edit.order_id" v-model="edit.dealer_comment"
                     class="form-control" type="text" @change="update(edit)">
              <span v-if="model.order_id != edit.order_id">{{model.dealer_comment}}</span>
            </td>
            <td>{{model.ship_date == null ? '' : new Intl.DateTimeFormat('ru-RU').format(new Date(model.ship_date))}}</td>
            <td width="70px;"></td>
            <td>
              <a :href="apiUrl + '/cartv2/print?id=' + model.order_id" target="_blank">
                <div class="print_blank"></div>
              </a>
            </td>
            <td >
              <a :href="apiUrl + '/file/xls?id=' + model.order_id" target="_blank">
                <img width="20px" :src="apiUrl +'/images/download.png'" alt="">
              </a>
            </td>
            <td>
                <img width="20px" @click="getAttachments(model.order_id)" :src="apiUrl +'/images/attachments.png'" alt="">
                <div class="col-xs-12">
                  <span v-for="(att, i) in attachments" :key="i">
                    <a v-if="att.order == model.order_id" :href="apiUrl + 'positions/' +att.link" target="_blank">{{att.name}}</a>
                  </span>
                </div>
            </td>

            <td>
              <a :href="apiUrl + '/ordersv2/xml?id=' + model.order_id" target="_blank">
                <div class="glyphicon glyphicon-eye-open"></div>
              </a>
            </td>
            <td>
              <a :href="apiUrl + '/ordersv2/xmlbasis?id=' + model.order_id" target="_blank">
                <div class="glyphicon glyphicon-eye-open"></div>
              </a>
            </td>
            <td>
              <div @click="copy(model.order_id)" class="glyphicon glyphicon-copy"></div>
            </td>
          </tr>
        </tbody>

      </table>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import pagination from "../../utils/pagination"
  import Http from '@/utils/http'

  export default {
    mixins: [Acl],
    name: 'type',
    data () {
      return {
        form:{
          number: null,
          state: 10,
          organisation: null,
          customer: null,
          email: null,
          phone: null,
          page: null,
        },
        edit:{},
        query: null,
        isActiveCss: '',
        attachments:[],
      }
    },
    components: {
      pagination
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        datalist: 'order/datalist',
        pagination: 'order/pagination',
        dealers: 'dealers/datalist',
        user: 'auth/user',
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader:'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        find: 'order/find',
        update: 'order/update',
        drop: 'order/delete',
        findDealers: 'dealers/findAll'
      }),
      setEdit(model){
        this.edit = Object.assign({}, model)
      },
      deleteData(id){
        this.setError(null)
        this.drop(id)
                .then(() =>{
                  this.findData()
                })
      },
      setPage(i){
        this.form.page = i
        this.findData()
      },
      findData(){
        return this.find(this.form)
      },
      copy(id){
        Http.get(this.apiUrl+'ordersv2/copy?id=' + id)
          .then((r) => {
            window.location.href = '/cart/' + r.data.order_id
          })
      },
      update(model){
        Http.post(this.apiUrl+'ordersv2/update', model)
          .then(() => {
            this.findData()
              .then(() => {
                this.edit = {}
              })

          })
      },
      getAttachments(id){
        Http.get(this.apiUrl+'ordersv2/attachments?id=' + id)
          .then(r => {
            this.attachments = r.data
          })
      },
      getDealer(id){
        var dealer = {}
        this.dealers.map(e => {
          if (e.id == id){
            dealer = e
            return
          }
        })
        return dealer
      },
      download(){
        window.location.href= this.apiUrl + 'ordersv2/download'
      },
      toOrder(order){
        this.$router.push("/cart/" + order.order_id)
      }
    },
    created() {
      this.setHeader('Список заказов')
      this.form.page = this.pagination.page
      this.findDealers()
      this.findData()
    }
  }
</script>
<style>
  input[type="file"] {
    display: none;
  }
  .city:hover{
    background-color: lightgrey;
  }
  img {
     max-width: inherit;
  }
</style>
