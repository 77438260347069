import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'
import Login from '../components/admin/login'
import Logout from '../components/admin/logout'
import Users from '../components/admin/users'
import Profile from '../components/admin/users/profile'
import ChangePassword from '../components/admin/users/changePassword'
import RightUser from '../components/admin/users/right'
import Restore from '../components/admin/restore'
import Activate from '../components/admin/activate'
import Pekcity from '../components/citypek'
import Warehouse from '../components/warehouses'
import Index from '../components/index/two_steps/index'
import ThirdStep from '@/components/index/third_step/index'
import FourthStep from '@/components/index/fourth_step/index'
import FifthStep from '@/components/index/fifth_step/index'
import Managerorderlist from '../components/managerorderlist'
import Dealers from '../components/dealers'
import type from "@/components/type";
import collection from "@/components/collection";
import price from '@/components/price'
import settings from '@/components/settings'
import analitic from '@/components/analitic'
import order from '@/components/order'
import typelinks from '@/components/typelinks'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated !== true) {
    next()
    return
  }
  next('/login')
}

const ifAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated === true) {
    next()
    return
  }
}


const check = (to, from, next) => {
  store.dispatch('auth/check')
    .then(() => {
      next()
    })
    .catch(error => {
      console.log('error: ', error)
      next('/login')
    })
  next()
}


export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/cart/:id',
      name: 'ThirdStep',
      component: ThirdStep,
      beforeEnter: check, ifNotAuthenticated,
    },
    {
      path: '/delivery/:id',
      name: 'FourthStep',
      component: FourthStep,
      beforeEnter: check, ifNotAuthenticated,
    },
    {
      path: '/finish/:id',
      name: 'FifthStep',
      component: FifthStep,
      beforeEnter: check, ifNotAuthenticated,
    },
    {
      path: '/',
      name: 'Home',
      component:Index,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: check, ifNotAuthenticated,
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/profile/:id',
      name: 'cliencardProfile',
      component: Profile,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/changepassword',
      name: 'changepassword',
      component: ChangePassword,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/rights/:id',
      name: 'rightsUser',
      component: RightUser,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/restore',
      name: 'restore',
      component: Restore,
    },
    {
      path: '/activate/:id',
      name: 'activate',
      component: Activate,
    },
    {
      path: '/pekcity',
      name: 'pekcity',
      component: Pekcity,
      beforeEnter: check, ifNotAuthenticated,
    },
    {
      path: '/warehouses',
      name: 'warehouses',
      component: Warehouse,
      beforeEnter: check, ifNotAuthenticated,
    },
    {
      path: '/managerorderlist',
      name: 'managerorderlist',
      component: Managerorderlist,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/dealers',
      name: 'dealers',
      component: Dealers,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/type',
      name: 'type',
      component: type,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/type/:id',
      name: 'typelinks',
      component: typelinks,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/collection',
      name: 'collection',
      component: collection,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/price',
      name: 'price',
      component: price,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/settings',
      name: 'settings',
      component: settings,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/order',
      name: 'order',
      component: order,
      beforeEnter: check, ifAuthenticated,
    },
    {
      path: '/analitic',
      name: 'analitic',
      component: analitic,
      beforeEnter: check, ifAuthenticated,
    },
  ],
})
