/* eslint-disable */
<template>
  <div class="sidebar clearfix">
    <ul>
      <li class="active buttons-nav">
        <div class="">
          <div class="col-sm-12" style="padding-right:0px">
            <div class="form-group buttons-step" style="margin-bottom: 0px;">
              <div class="col-xs-3" style="padding:0px; margin-top:20px;">
                <input type="text" class="form-control"
                       v-model="checkOrderNum" @keydown.prevent.enter="checkOrder"
                       placeholder="Номер заказа"
                       >
              </div>
              <div class="col-xs-9" style="padding:0px;padding-left:10px;margin-bottom: 0px;">
                <button style="width:197px;background-color: #DC143C;"
                        @click="checkOrder"
                        id="nextStepclick"
                        class="next-step">Проверить паспорт фасада</button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="active">
        <div class="li-title" @click="visibleCollection()">Выбор коллекции</div>
        <div class="hidden-block color-block clearfix"  v-if="collectionOpen === true">
          <p class="hb-title">Коллекции фасадов</p>
          <div class="baron_scroller clearfix">
            <div class="row">
              <div v-for="col in collections"
                   :key="col.collection_id"
                   class="facade-block clearfix col-xs-6"
                   @click="chooseCollection(col)"
                   :class="{active: col.collection_id === collection.id}"
                   :style="'margin-left:15px; background:url(' + encodeURI(host + col.filepath) + ')'"
              >
                <span>{{col.name}}</span>
              </div>
            </div>
          </div>
        </div><!-- /.hidden-block -->
      </li>

      <li class="" id="lifacade" @click="visibleFacade()" >
        <div class="li-title" >Выбор цвета фасада</div>
        <div class="hidden-block color-block clearfix" v-if="facadeIsVisible">
          <p class="hb-title">Цветовые решения</p>
          <div class="baron_scroller clearfix" id="facadeblock">
            <div class="row">
              <div  v-for="price in fasades" :key="price.price_id"
                    class="col-sm-6"
              >
                <div
                  class="color-container clearfix type7"
                >
                  <div class="color-img clearfix"
                       @click="chooseFasade(price.price_id)"
                       :class="{active: price.price_id === chosenFacade}"
                  >
                    <img :src="'https://api.fronts.shop.rhsolutions.ru' + encodeURI(price.price_filepath)"
                         :alt="price.design">
                  </div>
                </div>
                <div class="color-title">
                  <span>{{price.design}}</span>
                  <p>{{price.colorcode}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="" id="liborder" >
      <div class="" style="height:60px;">
        <button @click="createOrder"
                 id="btnnext"
                 type="button"
                 class="btn-more"
                 :style="createButtonStyle">Далее</button>
      </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import Http from '../../../../utils/http'
  import Vuex from 'vuex'
  export default {
    props: ['collections'],
    data() {
      return {
        collectionOpen: true,
        collectionFacadeName: null,
        fasadeOpen: true,
        borderOpen: false,
        fasades: {},
        chosenFacade: null,
        checkOrderNum: null,
      }
    },
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        collection:'index/collection',
        user: 'auth/user',
        storeFacade:'index/facade'
      }),
      facadeIsVisible() {
        if (this.fasadeOpen && !this.collectionOpen) {
          return true
        }
        if (this.collection.id && !this.collectionOpen) {
          return true
        }
        return false
      },
      createButtonStyle() {
        if (this.chosenFacade !== null) {
          return 'position:relative;display:inline-block;margin-top:29px;margin-left:30px;height:40px;'
        } else {
          return 'position:relative;background-color:#d9d9d9;display:inline-block;margin-top:29px;margin-left:30px;height:40px;'
        }
      }
    },
    methods: {
      ...Vuex.mapMutations({
        setStoreFacade: 'index/setFacade'
      }),
      visibleCollection() {
        this.closeAll()
        this.collectionOpen = true
      },
      chooseCollection(collection) {
        this.$store.commit('index/setCollectionId', collection.collection_id)
        this.chosenFacade = null
        this.collectionFacadeName = collection.name
        this.getFasades()
        this.visibleFacade()
        this.getImgUrl()
      },
      visibleFacade() {
        this.closeAll()
        this.fasadeOpen = true
      },
      chooseFasade(priceId) {
        this.chosenFacade = priceId
        this.setStoreFacade(priceId)
        this.visibleBorder()
        this.getImgUrl()
      },
      visibleBorder() {
        this.closeAll()
        this.borderOpen = true
      },
      closeAll(){
        this.collectionOpen = false
        this.fasadeOpen = false
        this.borderOpen = false
      },
      getFasades() {
        this.fasades = {}
        var that = this
        if (this.collection.id !== 0) {
            Http.get(this.host + 'pricev2/apilist?collection_id=' + this.collection.id)
            .then(function(response) {
              that.fasades = response.data;
            });
        }
      },
      getImgUrl() {
        var that = this;
          Http.post(this.host + 'index.php/ordersv2/getbackgroundurl', {
            collection_id: this.collection.id,
            price_id: that.chosenFacade,
          })
          .then(function(response) {
              that.$store.commit('index/setCollectionImg', response.img)
          });
      },
      checkOrder(){
        if (this.checkOrderNum != null && this.checkOrderNum.length > 1){
          window.open('https://api.fronts.shop.rhsolutions.ru/ordersv2/check?order_id='+this.checkOrderNum, '_blank');
        }
      },
      createOrder() {
        if (!this.chosenFacade) {
          return false
        }
        this.setFacadeBorder(this.chosenFacade)
        if (this.user.id != null) {
          this.$emit('createOrder')
        } else {
          this. $router.push('/login')
        }
      },
      setFacadeBorder(facadeId){
        this.$emit('setFacadeBorder', facadeId)
      },
      checkFacadesAndCollectionsIsOpen() {
        if (this.collection.id && this.fasadeOpen === true) {
          this.collectionOpen = false

          if (this.storeFacade != null) {
            this.chooseFasade(this.storeFacade)
          }
        }
      }
    },
    created() {
      this.checkFacadesAndCollectionsIsOpen()
      this.getFasades()
    }
  }
</script>

<style scoped>
@media (max-width: 359px) {
    .facade-block {
        width: 65vw !important;
        background-size: cover !important;
    }

    .color-img {
        width: 65vw !important;
        background-size: cover !important;
    }

    .btn-more {
        width: 67vw;
        margin-left: 9vw !important;
    }


    #nextStepclick {
        width: 85vw !important;
        white-space: nowrap;
    }

    .form-group .col-xs-3 {
        width: 60%;
        text-align: center;
    }

    .form-group .col-xs-3 input {
        text-align: center;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}


@media (max-width: 420px) and (min-width: 360px)  {
    .buttons-nav {
        width: 89vw !important;
    }

    .btn-more {
        width: 67vw;
        margin-left: 9vw !important;
    }

    .hidden-block {
        width: 88vw;
    }

    #nextStepclick {
        width: 55vw !important;
        white-space: nowrap;
    }

  .form-control {
    text-align: center;
    width: 24vw;
      flex-direction: row;
  }

  .col-sm-6 {
    padding: 6px;
  }

  .facade-block {
    margin-left: 10px !important;
      width: 37vw;
  }

    .color-img {
        margin-left: 10px !important;
        width: 34vw;
    }

  .row {
    display: flex;
    flex-wrap: wrap;
    
  }
}
.col-sm-6 {
  height: 170px;
}

.row {
  width: 100%;
}
  .hidden-block {
    display: block;
    height: 504px;
  }
  .hidden-block>div:nth-child(odd) .facade-block {
    float: left;
  }
  img {
    object-fit: cover;
    max-width: 100%;
  }
</style>
