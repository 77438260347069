<template>
  <section id="step_4" class="clearfix">
    <div v-if="order!=null" class="container">
      <div class="col-lg-5 col-md-6 col-sm-7">
        <div class="row">
          <div class="parameter-block clearfix">
            <span>4</span>
            <h2>Оформление доставки</h2>
            <p class="parameter-descr">Заполните контактные данные и данные для доставки</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-2 col-sm-1">
        <div class="row">
          <div class="line-gray"></div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4">
        <div class="row">
          <div class="ordering clearfix">
            <h3 style="margin-top:0px">
              Заказ №{{order.order_id}}
              <br>на сумму {{order.sumvalue}} руб.
              <input v-if="user.username" type="text" class="form-control" v-model="designerId" @change="updateOrder" placeholder="Id дизайнера">
            </h3>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    
      <p class="step4-title text-center">Контактные данные</p>
        <div class="col-sm-2">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-organization">
              <label class="control-label active">Организация</label>
                <input type="text" class="form-control" v-model="organisationName" @change="updateOrder" placeholder="Организация">
              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-surname required">
              <label class="control-label active" :style="{color: canSurname? 'gray' : '#DC143C'}">Фамилия</label>
              <input type="text" class="form-control formname" v-model="surname" @change="updateOrder" placeholder="Ваша фамилия">
              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-name required">
              <label class="control-label active" :style="{color: canName? 'gray' : '#DC143C'}" >Ваше имя</label>
              <input type="text" class="form-control formname" v-model="name" @change="updateOrder" placeholder="Ваше имя">
              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-phone required">
              <label class="control-label active" :style="{color: canPhone ? 'gray' : '#DC143C'}" >Контактный телефон</label>
              <input type="text" class="form-control formname" v-model="phone" @change="updateOrder" placeholder="Телефон" >
              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-email required">
              <label class="control-label active" :style="{color: canEmail ? 'gray' : '#DC143C'}" >Почта</label>
              <input type="text" class="form-control formname" v-model="email" @change="updateOrder" placeholder="E-mail">
              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <!--Детали доставки-->
        <div class="clear"></div>
        <p class="step4-title text-center">Габариты заказа</p>
        <div class="clear" style="height:20px;"></div>
        <div class="col-sm-2">
          <div class="form-group contact-block">
            <div class="form-group field-orders-dlength">
              <label class="control-label active">Длина(см)</label>
              <input type="text" class="form-control" :value="order.dlength" disabled="disabled" placeholder="Длина">

              <div class="help-block"></div>
            </div>                            </div><!--/.form-group -->
        </div><!--/.col-sm-2 -->
        <div class="col-sm-2">
          <div class="form-group contact-block">
            <div class="form-group field-orders-dwidth">
              <label class="control-label active">Ширина(см)</label>
              <input type="text" class="form-control" :value="order.dwidth" disabled="disabled" placeholder="Ширина">
              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group contact-block">
            <div class="form-group field-orders-dheight">
              <label class="control-label active">Высота(см)</label>
              <input type="text" class="form-control" :value="order.dheight" disabled="disabled" placeholder="Высота">
              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group contact-block">
            <div class="form-group field-orders-dweight">
              <label class="control-label active">Вес(кг)</label>
              <input type="text" class="form-control" :value="order.dweight" disabled="disabled" placeholder="Вес">
              <div class="help-block"></div>
            </div>
          </div>
        </div>

        <div v-if="zone > 0  && user != null && user.organisation != null && user.organisation.type_id == 10">
          <orderdealer :zone="zone"/>
        </div>
        <div v-if="user != null && user.organisation != null && user.organisation.type_id == 20 &&
            user.organisation.linked != null && user.organisation.linked.length > 0">
          <ordersaloon/>
        </div>
      <div v-if="user != null && user.organisation != null && user.organisation.type_id == 20 &&
            (user.organisation.linked == null || user.organisation.linked.length == 0)">
        <ordersaloonwithoutlinked />
      </div>
      </div>
  </section>
</template>
<script>
  import Http from "../../../utils/http";
  import Vuex from 'vuex'
  import Orderdealer  from "./order_dealer";
  import Ordersaloon  from "./order_saloon";
  import Ordersaloonwithoutlinked  from "./order_saloon_without_linked";

  export default {
    data(){
      return {
        orderId: null,
        organisationName: null,
        surname: null,
        name: null,
        phone: null,
        email:null,
        cityId: '',
        comment: null,
        hardPack: false,
        greatPack: false,
        selected: null,
        longitude: null,
        latitude: null,
        addrlocation: null,
        designerId: null,
        saloonnum: null,
        saloonreal: '',
        sort:1,
        zone:''
      }
    },
    components:{
      orderdealer: Orderdealer,
      ordersaloon: Ordersaloon,
      ordersaloonwithoutlinked: Ordersaloonwithoutlinked
    },
    methods: {
      ...Vuex.mapActions({
        finddealers: 'dealers/find',
        findwarehouses: 'warehouses/find',
        findorder: 'order/get',
        updateorder: 'order/update'
      }),
      ...Vuex.mapMutations({
        setdealers: 'dealers/set'
      }),
      setSaloon(saloon) {
        this.saloonreal = saloon.saloonnum
        this.saloonnum = saloon.saloonnum
        this.updateOrder()
        this.setdealers([])
      },
      findSaloons() {
        this.finddealers({
          query: this.saloonnum,
          type_id: 20,
          limit: 5,
        })
      },
      getInfoByOrderId(){
        this.findorder(this.orderId)
        .then(() => {
          this.organisationName = this.order.organization
          this.surname = this.order.surname
          this.name = this.order.name
          this.phone = this.order.phone
          this.email = this.order.email
          this.comment = this.order.comment
          this.greatPack = this.order.dpackage
          this.hardPack = this.order.hardpack
          this.cityId = this.order.city_delivery
          this.designerId = this.order.designer_id
          this.zone = this.order.zone
        })
      },
      updateOrder() {
        var newOrd = Object.assign({}, this.order)
        newOrd.organization = this.organisationName
        newOrd.surname = this.surname
        newOrd.name = this.name
        newOrd.phone = this.phone
        newOrd.email = this.email
        newOrd.comment = this.comment
        newOrd.dpackage = this.greatPack
        newOrd.hardpack = this.hardPack
        newOrd.city_delivery = this.cityId
        newOrd.designer_id = this.designerId
        this.updateorder(newOrd)
      },
      findTown(set){
        if (!(set > 0)) {
          this.cityId = null
        }
        this.$store.commit('index/setItemTowns', [])
        Http.get(this.host + 'citypekv2/findapi?q=' + this.selected)
          .then((response) =>  {
            this.$store.commit('index/setItemTowns', response)
            if (set > 0 && this.items.length > 0) {
              this.setTown(this.items[0])
            }
          })
          .catch(function (error) {
            console.log(error)
          });
      },
    },
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        positions: 'index/positions',
        sumAllPositions: 'index/sumAllPositions',
        user: 'auth/user',
        points: 'index/points',
        itemTowns: 'index/itemTowns',
        saloons: 'dealers/datalist',
        settings: 'app/settings',
        warehouses: 'warehouses/datalist',
        order: 'order/order',
      }),
      items(){
        return this.itemTowns
      },
      dealers(){
        var points = this.points
        if (this.sort == 2) {
          return points.sort(function(a, b){
            return a.money - b.money
          })
        } else {
          return points.sort(function (a, b) {
            return a.days - b.days
          })
        }
      },
      canAccept(){
        if (this.canEmail == false){
          return false
        }
        if (this.canName == false){
          return false
        }
        if (this.canPhone == false){
          return false
        }
        if (this.canSurname == false){
          return false
        }
        if (this.canSaloonnum == false){
          return false
        }
        return true
      },
      canSaloonnum(){
        if (this.user == null || this.user.id == null) {
          return true
        }
        
        if (this.saloonreal == null || this.saloonreal == '') {
          return false
        }

        return true
      },
      canPhone(){
        if (this.phone == null || this.phone == '') {
          return false
        }
        return true
      },
      canSurname(){
        if (this.surname == null || this.surname == '') {
          return false
        }
        return true
      },
      canName(){
        if (this.name == null || this.name == '') {
          return false
        }
        return true
      },
      canEmail(){
        if (this.email == null || this.email == '') {
          return false
        }
        return true
      },
      canTown(){
        if (this.cityId == null || this.cityId == '') {
          return false
        }
        return true
      },
    },
    created(){
      if (+this.$route.params.id != null) {
        this.orderId = +this.$route.params.id
        console.log(this.user)
        if (this.user != null && this.user.organisation != null && (this.saloonreal == null || this.saloonreal == "")){
          this.saloonreal = this.user.organisation.saloonnum
          if (this.saloonreal == null) {
            this.saloonreal = ''
          } 
        }
      }
      this.getInfoByOrderId()
    },
    mounted: function() {
      this.findwarehouses()
    }
  }

</script>
<style>
  .dinamicSelect{
    position: absolute;
    z-index: 2;
    background-color: white;
  }

  .dinamicSelect :hover{
   background-color: #4FC7B5;
   color: white;
  }

  .hw :hover{
    background-color: #4FC7B5;
    color: white;
  }
</style>
