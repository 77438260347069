<template>
  <section>
      <div class="form-row">
        <div class="col-1">
          <button class="btn btn-primary" @click="saveData">Добавить</button>
        </div>
        <div class="col-7"></div>
        <pagination class="col-4" @setPage="setPage" :padding="2" :totalpages="pagination.total/pagination.limit" :page="pagination.page"></pagination>
      </div>
      <table class="table table-border table-hover">
        <tr>
          <th>Название</th>
          <th>Адрес</th>
          <th>Рабочих дней</th>
          <th>Широта</th>
          <th>Долгота</th>
          <th>Зона</th>
          <th></th>
          <th></th>
        </tr>
        <tbody v-for="model in datalist" :key="model.id">
          <tr v-if="edit == null || edit.id != model.id">
            <td>{{model.name}}</td>
            <td>{{model.adress}}</td>
            <td>{{model.preparation}}</td>
            <td>{{model.shirota}}</td>
            <td>{{model.dolgota}}</td>
            <td>{{zones[model.zone]}}</td>
            <td><a class="glyphicon glyphicon-pencil" @click="toChange(model)" ></a></td>
            <td width="60px;"><vue-confirmation-button
                    :css="'glyphicon glyphicon-trash'"
                    v-on:confirmation-success="deleteData(model.id)">
            </vue-confirmation-button></td>
          </tr>
          <tr v-if="edit != null && edit.id == model.id">
            <td><input type="text" class="form-control" v-model="edit.name" placeholder="Название"></td>
            <td><input type="text" class="form-control" v-model="edit.adress" placeholder="Адрес"></td>
            <td><input type="text" class="form-control" v-model="edit.preparation" placeholder="Рабочих дней"></td>
            <td><input type="text" class="form-control" v-model="edit.shirota" placeholder="Широта"></td>
            <td><input type="text" class="form-control" v-model="edit.dolgota" placeholder="Долгота"></td>
            <td><select class="form-control" v-model="edit.zone" >
                <option value=''>выберте зону</option>
                <option v-for="(zone, i) in zones" :key="i" :value="i">{{zone}}</option>
              </select>
            </td>
            <td><a class="glyphicon glyphicon-ok" @click="update"></a></td>
            <td><a class="glyphicon glyphicon-remove" @click="edit = null"></a></td>
          </tr>
        </tbody>

      </table>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import vueConfirmationButton from '../../utils/confirmButton'
  import pagination from "../../utils/pagination";

  export default {
    mixins: [Acl],
    name: 'warehouses',
    data () {
      return {
        form:{
          name: 'Новый',
          adress: 'неизвестный',
          preparation: 0,
          shirota: 1,
          dolgota: 1,
          zone: 10
        },
        page: null,
        edit: null,
      }
    },
    components: {
      VueConfirmationButton: vueConfirmationButton,
      pagination
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        datalist: 'warehouses/datalist',
        pagination: 'warehouses/pagination',
        zones: 'app/zones'
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader:'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        save: 'warehouses/save',
        find: 'warehouses/find',
        drop: 'warehouses/delete'
      }),
      saveData(){
        this.setError(null)
        this.save(this.form)
                .then(() =>{
                  this.findData()
                })
      },
      update() {
        this.setError(null)
        this.save(this.edit)
                .then(() =>{
                  this.edit = null
                  this.findData()
                })
      },
      deleteData(id){
        this.setError(null)
        this.drop(id)
                .then(() =>{
                  this.findData()
                })
      },
      toChange(model){
        this.edit = Object.assign({}, model)
      },
      setPage(i){
        this.page = i
        this.findData()
      },
      findData(){
        this.find({page:this.page})
      }
    },
    created() {
      this.setHeader('Склады')
      this.page = this.pagination.page
      this.findData()
    }
  }
</script>
