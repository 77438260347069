<template>
  <section>
    <table class="table table-striped table-bordered table-hover">
      <tr>
        <th>Надбавка на цену в % для незарегистрированых</th>
        <th>Зона</th>
        <th>Скидка дилера</th>
        <th>Наценка салона</th>
        <th>Тестовые почтовые адреса(через запятую)</th>
        <th>Цена присадки</th>
        <th>Включить тестовый режим отправки писем</th>
        <th>Радиус</th>
        <th>Описание присадки</th>
        <th>Описание упаковки</th>
        <th>Описание скидки</th>
        <th>Транспорт %</th>
        <th>Улучшенная упаковка</th>
        <th>Стоимость присадки для гнутого фасада</th>
        <th>Минимальная полщадь детали с повышающим коэффициентом</th>
        <th>Повышающий коэффициент для детали с минимальной площадью</th>
        <th>Курсевро</th>
        <th>Подложка</th> 
        <th></th>
        <th></th>
      </tr>
      <tbody v-for="model in datalist" :key="model.id">
      <tr v-if="edit == null || edit.id != model.id">
        <td>{{model.bonus}}</td>
        <td>{{model.zone == 10 ? 'Россия' : 'Беларусь'}}</td>
        <td>{{model.dealer_discount}}</td>
        <td>{{model.store_discount}}</td>
        <td>{{model.email}}</td>
        <td>{{model.loopcost}}</td>
        <td>{{model.testing == 1 ? 'Включен' : 'Выключен'}}</td>
        <td>{{model.radius}}</td>
        <td>{{model.desc_loop}}</td>
        <td>{{model.desc_package}}</td>
        <td>{{model.discount}}</td>
        <td>{{model.cargo_multi}}</td>
        <td>{{model.greatpack}}</td>
        <td>{{model.gnuadder}}</td>
        <td>{{model.min_square_detail_for_multy}}</td>
        <td>{{model.multy_min_square_detail}}</td>
        <td>{{model.eur_course}}</td>
        <td>{{model.background}}</td>
        <td><a class="glyphicon glyphicon-pencil" @click="toChange(model)"></a></td>
        <td width="100px;"></td>
      </tr>
      <tr v-if="edit != null && edit.id == model.id">
        <td><input type="text" class="form-control" v-model="edit.bonus"
                   placeholder="Надбавка на цену в % для незарегистрированых"></td>
        <td>{{model.zone == 10 ? 'Россия' : 'Беларусь'}}</td>
        <td><input type="text" class="form-control" v-model="edit.dealer_discount" placeholder="Скидка дилера"></td>
        <td><input type="text" class="form-control" v-model="edit.store_discount" placeholder="Наценка салона"></td>
        <td><input type="text" class="form-control" v-model="edit.email"
                   placeholder="Тестовые почтовые адреса(через запятую)"></td>
        <td><input type="text" class="form-control" v-model="edit.loopcost" placeholder="Цена присадки"></td>
        <td>
          <select class="form-control" v-model="edit.testing">
            <option value="0">Выключен</option>
            <option value="1">Включен</option>
          </select>
        </td>
        <td><input type="text" class="form-control" v-model="edit.radius" placeholder="Радиус"></td>
        <td><input type="text" class="form-control" v-model="edit.desc_loop" placeholder="Описание присадки"></td>
        <td><input type="text" class="form-control" v-model="edit.desc_package" placeholder="Описание упаковки"></td>
        <td><input type="text" class="form-control" v-model="edit.discount" placeholder="Описание скидки"></td>
        <td><input type="text" class="form-control" v-model="edit.cargo_multi" placeholder="Транспорт %"></td>
        <td><input type="text" class="form-control" v-model="edit.greatpack" placeholder="Улучшенная упаковка"></td>
        <td><input type="text" class="form-control" v-model="edit.gnuadder" placeholder="Стоимость присадки для гнутого фасада"></td>
        <td><input type="text" class="form-control" v-model="edit.min_square_detail_for_multy" placeholder="Минимальная полщадь детали с повышающим коэффициентом"></td>
        <td><input type="text" class="form-control" v-model="edit.multy_min_square_detail" placeholder="Повышающий коэффициент для детали с минимальной площадью"></td>
        <td><input type="text" class="form-control" v-model="edit.eur_course" placeholder="Курс евро"></td>
        <td><input type="text" class="form-control" v-model="edit.background" placeholder="Подложка"></td>
        <td><a class="glyphicon glyphicon-ok" @click="update"></a></td>
        <td><a class="glyphicon glyphicon-remove" @click="edit = null"></a></td>
      </tr>
      </tbody>

    </table>

    <td>
      <button class="btn btn-primary" @click="() => showModalCollecion(true)">Загрузить фоновое фото</button>
      <!-- <label :for="'upload-bgfilepath'">
        <img src="https://api.fronts.shop.rhsolutions.ru/images/background.png" alt="">
        <span v-if="settings.background == null || settings.background == ''">Загрузить фон</span>
      </label>
      <input
        :id="'upload-bgfilepath'"
        type="file"
        name="img"
        class="form-control "
        :ref="'bgfilepath'"
        @change="uploadImg()"
      /> -->
    </td>
    <div class="modal" v-if="modalVisible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-title">Текущее фоновое фото</div>
            <img src="https://api.fronts.shop.rhsolutions.ru/images/background.png" alt="" style="width: 100%;"/>
            <div class="modal-body_button">
              <label :for="'upload-img'">
                <span class="btn btn-primary">Загрузить фон</span>
              </label>
              <input
                :id="'upload-img'"
                type="file"
                name="img"
                class="form-control "
                :ref="'bgfilepath'"
                @change="uploadImg()"
              />
            </div>
          </div>
          <div class="modal-footer">
            <a href="" id="cancel"  @click.stop.prevent="() => showModalCollecion(false)" class="btn btn-secondary">Закрыть</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import Http from "@/utils/http";
  export default {
    mixins: [Acl],
    name: 'type',
    data() {
      return {
        form: {
          name: 'Новый',
        },
        page: null,
        edit: null,
        modalVisible: false,
      }
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        datalist: 'settings/datalist',
        pagination: 'settings/pagination',
        settings: 'app/settings'
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader: 'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        save: 'settings/save',
        find: 'settings/find',
      }),
      saveData() {
        this.setError(null)
        this.save(this.form)
          .then(() => {
            this.findData()
          })
      },
      update() {
        this.setError(null)
        this.save(this.edit)
          .then(() => {
            this.edit = null
            this.findData()
          })
      },
      showModalCollecion (isVisible) {
        this.modalVisible = isVisible;
      },
      uploadImg() {
        var formData = new FormData()
        var file = this.$refs['bgfilepath']
        // Http.get(this.apiUrl + '/settingsv2/background?filename=' + file['files'][0].name).then((rsp) => {
        //   console.log(rsp.data[0])
        //   var formData = new FormData()
        //   formData.append('file', file['files'][0])
          
        // }).catch((err) => {
        //   this.setError(err)
        // })
        formData.append('img', file['files'][0])
        Http.post(this.apiUrl + 'backgroundimage/upload',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
              }
          ).then(() => {
            this.showModalCollecion(false)
            this.findData()
          }).catch((err) => {
            this.showModalCollecion(false)
            this.setError(err)
          })
      },
      toChange(model) {
        this.edit = Object.assign({}, model)
      },
      findData() {
        this.find({page: this.page})
      }
    },
    created() {
      this.setHeader('Настройки')
      this.page = this.pagination.page
      this.findData()
    }
  }
</script>

<style scoped>
.modal {
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, .07);
  display: block;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: all .3s linear;
  width: 100vw;
  z-index: 2500;
}
.modal-body_title {
  text-align: center;
  color: #D03D72;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.modal-body_button {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
