import Http from '../../utils/http'
import Config from "@/config";
const state = {
  datalist:[],
  page: null,
  total: null,
  limit: null,
  manufactures: [],
}
const getters = {
  datalist: state => state.datalist,
  page: state => state.page,
  pagination: state => {
    return {total: state.total, limit: state.limit, page: state.page}
  },
  manufactures: state => state.manufactures,
}
const mutations = {
  setOrders: (state, orders) => {
    state.datalist = orders
  },
  setOrder: (state, order) => {
    for (let idx = 0 ; idx < state.datalist.length; idx++) {
      if (state.datalist[idx].order_id == order.order_id) {
        state.datalist[idx] = order
        break
      }
    }
  },
  setManufactures: (state, manufactures) => {
    state.manufactures = manufactures
  },
  pagination:(state, pagination) => {
    state.page = pagination.page ? pagination.page : null
    state.total = pagination.total ? pagination.total : null
    state.limit = pagination.limit ? pagination.limit : null
  },
}

const actions = {
  getOrders: ({commit}, form) => {
    return new Promise((resolve) => {
      Http.post(Config.apiUrl + 'ordersv2/managerorderlist', form)
        .then(resp => {
          commit('setOrders', resp.data)
          commit('pagination', resp.pagination)
          resolve(resp)
        })
    })
  },
  getManufactures: ({commit}) => {
    return new Promise((resolve) => {
      Http.get(Config.apiUrl + 'manufacture')
        .then(resp => {
          commit('setManufactures', resp)
          resolve(resp)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
