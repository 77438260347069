<template>
  <section>
    <managerorderlist :type="'analitic'"></managerorderlist>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import managerorderlist from "@/components/managerorderlist";

  export default {
    mixins: [Acl],
    name: 'analitic',
    data () {
      return {
      }
    },
    components: {
      managerorderlist
    },
  }
</script>
