<template>
  <section :class="isActiveCss">
    <div class="form-row">
      <div class="col-xs-1">
        <button class="btn btn-primary" style="margin-top: 0px;" @click="saveData">Добавить</button>
      </div>
      <div class="col-xs-5">
        <div class="col-xs-6">
          <input class="form-control" @keyup="findData" @change="findData" v-model="query" type="text">
        </div>
        <div class="col-xs-3">
          <select class="form-control" @change="findData" v-model="form.type_id">
            <option value="">Все</option>
            <option value="10">Дилер</option>
            <option value="20">Салон</option>
          </select>
        </div>
        <div class="col-xs-3">
          <select class="form-control" @change="findData" v-model="form.zone">
            <option value="">Все</option>
            <option value="10">Россия</option>
            <option value="20">Беларусь</option>
          </select>
        </div>
      </div>
      <div class="col-xs-1">
        <button class="btn btn-primary" @click="download" style="margin-top: 0px;">Скачать</button>
      </div>
      <div class="col-xs-1">
        <label for="file-upload" class="btn btn-primary" style="margin-top: 0px;padding-left: 12px;">Загрузить
        </label>
        <input id="file-upload" type="file" name="f" class="form-control " ref="file" @change="upload()">
      </div>
      <div class="col-xs-4">
        <pagination class="pull-right" style="margin-top: 0px;" @setPage="setPage" :padding="2"
                    :totalpages="pagination.total/pagination.limit" :page="pagination.page"></pagination>
      </div>
    </div>
    <table class="table table-bordered table-hover">
      <tr>
        <th>Название</th>
        <th>Номер дилера</th>
        <th>Номер салона</th>
        <th>Тип</th>
        <th>Зона</th>
        <th>Адрес</th>
        <th>Email</th>
        <th>Широта</th>
        <th>Долгота</th>
        <th>Юр.лицо</th>
        <th>Город</th>
        <th>Скидка</th>
        <th>Телефон</th>
        <th>Сайт</th>
        <th>Связь</th>
        <th></th>
        <th></th>
      </tr>
      <tbody v-for="model in datalist" :key="model.id">
      <tr v-if="edit == null || edit.id != model.id">
        <td>{{model.name}}</td>
        <td>{{model.dealernum}}</td>
        <td>{{model.saloonnum}}</td>
        <td>{{(model.type_id == 10 ? 'Дилер' : 'Салон')}}</td>
        <td>{{(model.zone == 10 ? 'Россия' : 'Беларусь ')}}</td>
        <td>{{model.adress}}</td>
        <td>{{model.email}}</td>
        <td>{{model.k1}}</td>
        <td>{{model.k2}}</td>
        <td>{{model.org_law}}</td>
        <td>{{model.name_pek}}</td>
        <td>{{model.discount}}</td>
        <td>{{model.phone}}</td>
        <td>{{model.url}}</td>
        <td><span v-if="model.type_id == 20" @click="showLinked(model)">Связи</span></td>
        <td><a class="glyphicon glyphicon-pencil" @click="toChange(model)"></a></td>
        <td width="100px;">
          <vue-confirmation-button
            :css="'glyphicon glyphicon-trash'"
            v-on:confirmation-success="deleteData(model.id)">
          </vue-confirmation-button>
        </td>
      </tr>
      <tr v-if="edit != null && edit.id == model.id">
        <td><input type="text" class="form-control" v-model="edit.name" placeholder="Название"></td>
        <td><input type="text" class="form-control" v-model="edit.dealernum" placeholder="Номер дилера"></td>
        <td><input type="text" class="form-control" v-model="edit.saloonnum" placeholder="Номер салона"></td>
        <td>
          <select class="form-control" @change="findData" v-model="edit.type_id">
            <option value="10">Дилер</option>
            <option value="20">Салон</option>
          </select>
        </td>
        <td>
          <select class="form-control" @change="findData" v-model="edit.zone">
            <option value="10">Россия</option>
            <option value="20">Беларусь</option>
          </select>
        </td>
        <td><input type="text" class="form-control" v-model="edit.adress" placeholder="Адрес"></td>
        <td><input type="text" class="form-control" v-model="edit.email" placeholder="email"></td>
        <td><input type="text" class="form-control" v-model="edit.k1" placeholder="широта"></td>
        <td><input type="text" class="form-control" v-model="edit.k2" placeholder="Долгота"></td>
        <td><input type="text" class="form-control" v-model="edit.org_law" placeholder="юр/лицо"></td>
        <td>
          <input type="text" class="form-control" v-model="edit.name_pek" @keyup="findCity" placeholder="Город">
          <div class="city" style="width:100%;" v-for="(ct, i) in city" :key="i" @click="edit.city_id = ct.id_pek; edit.name_pek = ct.name_pek;  city = []" >{{ct.name_pek}}</div>
        </td>
        <td><input type="text" class="form-control" v-model="edit.discount" placeholder="скидка"></td>
        <td><input type="text" class="form-control" v-model="edit.phone" placeholder="телефон"></td>
        <td><input type="text" class="form-control" v-model="edit.url" placeholder="сайт"></td>
        <td></td>
        <td><a class="glyphicon glyphicon-ok" @click="update"></a></td>

      </tr>
      </tbody>
    </table>
    <div v-if="showLink" class="modal" tabindex="-1" role="dialog" style="display: block">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Связи для {{linkedit.name}}</h5>
            <button type="button" @click="showLink = false" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="min-height: 40vh">
            <input type="text" class="form-control" v-model="newLinkName" @keyup="findDealer">
            <div v-if="searchLink.length > 0" class="col-12">
                <div @click="addLink(ln)" class="col-xs-12 hovrlink" v-for="ln in searchLink" :key="ln.id">
                  {{ln.name}}
                </div>
            </div>
            <div v-if="links.length > 0" class="col-12">
              <div class="col-xs-12" v-for="(ln, i) in links" :key="i">
                <div class="col-xs-10">{{getNameById(ln)}}</div>
                <div @click="removeLink(ln)" class="col-xs-2">X</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import vueConfirmationButton from '../../utils/confirmButton'
  import pagination from "../../utils/pagination";
  import Http from "@/utils/http";

  export default {
    mixins: [Acl],
    name: 'citypek',
    data() {
      return {
        form: {
          name: 'Новый',
          type_id: 10,
          zone: 10,
        },
        query: null,
        page: null,
        edit: null,
        isActiveCss: '',
        city:[],
        showLink: false,
        linkedit: {},
        links: [],
        newLinkName: null,
        searchLink:[],
        fullDealers:[]
      }
    },
    components: {
      VueConfirmationButton: vueConfirmationButton,
      pagination
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        datalist: 'dealers/datalist',
        pagination: 'dealers/pagination',
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader: 'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        save: 'dealers/save',
        find: 'dealers/find',
        drop: 'dealers/delete'
      }),
      saveData() {
        this.setError(null)
        this.save(this.form)
          .then(() => {
            this.findData()
          })
      },
      update() {
        this.setError(null)
        this.save(this.edit)
          .then(() => {
            this.edit = null
            this.findData()
          })
      },
      deleteData(id) {
        this.setError(null)
        this.drop(id)
          .then(() => {
            this.findData()
          })
      },
      toChange(model) {
        this.edit = Object.assign({}, model)
      },
      setPage(i) {
        this.page = i
        this.findData()
      },
      findData() {
        this.find({page: this.page, query: this.query, type_id: this.form.type_id, zone: this.form.zone})
      },
      download() {
        window.location = this.apiUrl + 'dilersv2/download'
        return
      },
      findCity(){
        if (this.edit != null) {
          Http.get(this.apiUrl + '/citypekv2?query=' + this.edit.name_pek)
            .then((r) => {
              this.city = r.data
            })
        }
      },
      addLink(dealer){
        if (dealer == null ){
          return
        }
        var exist = false
        this.links.map(e => {
          if (+dealer.id == +e){
            exist = true
            return
          }
        })
        if (exist == true){
          return
        }
        this.links.push(dealer.id)
        this.saveLinks()
      },
      removeLink(id){
        var newlink =[]
        this.links.map(e => {
          if (+e != +id) {
            newlink.push(e)
          }
        })
        this.links = newlink
        this.saveLinks()
      },
      findDealer(){
        if (this.newLinkName == '' || this.newLinkName == null){
          this.searchLink = []
          return
        }
        Http.get(this.apiUrl + '/dilersv2?query=' + this.newLinkName + '&type_id=10&zone=' + this.linkedit.zone)
          .then((r) => {
            this.searchLink = r.data
          })
      },
      saveLinks(){
        this.linkedit.linked = JSON.stringify(this.links)
        this.save(this.linkedit)
          .then(() => {
            this.findData()
          })
      },
      showLinked(model){
        console.log
        this.linkedit = Object.assign({}, model)
        if (this.linkedit.linked != null && this.linkedit.linked.length > 0) {
          this.links = JSON.parse(this.linkedit.linked)
        }
        this.showLink = true
      },
      getNameById(id){
        var nm = ''
        this.fullDealers.map(e => {
          if (e.id == id){
            nm = e.name
            return
          }
        })
        return nm
      },
      upload() {
        this.isActiveCss = 'disabled'
        this.setError(null)
        var formData = new FormData()
        formData.append('file', this.$refs.file.files[0])
        Http.post(this.apiUrl + '/dilersv2/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        ).then(() => {
          this.findData()
          this.isActiveCss = ''
        }).catch((err) => {
          this.setError(err)
          this.isActiveCss = ''
        })
      }
    },
    created() {
      this.setHeader('Дилеры')
      this.page = this.pagination.page
      this.findData()
      Http.get(this.apiUrl + 'dilersv2?limit=0')
        .then((r) => {
          this.fullDealers = r.data
        })
    }
  }
</script>
<style>
  input[type="file"] {
    display: none;
  }
  .city:hover{
    background-color: lightgrey;
  }
  .hovrlink:hover{
    background-color: #D03D72;
    color:white;
  }
</style>
