import Vue from 'vue'
import Vuex from 'vuex'
import app from './admin/app'
import auth from './admin/auth'
import navigation from './admin/navigation'
import citypek from './components/citypek';
import warehouses from './components/warehouses';
import index from './components/index'
import managerorderlist from './components/managerorderlist'
import dealers from './components/dealers'
import type from "@/store/components/type";
import collection from "@/store/components/collection";
import price from "@/store/components/price";
import settings from "@/store/components/settings";
import order from "@/store/components/order";
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    app,
    auth,
    navigation,
    citypek,
    warehouses,
    index,
    managerorderlist,
    dealers,
    type,
    collection,
    price,
    settings,
    order
  },
  strict: debug,
})
