<template>
  <section>
    <div v-for="(position, idx) in positions " :key="position.position_id">
      <app-position :position="position"
                    :idx="idx"
                    :collections="collections"
                    :types="types"
                    @deletePosition="deletePosition"></app-position>
    </div>
  </section>
</template>
<script>
  import position from './position'
  import Vuex from 'vuex'
  export default {
    components: {
      appPosition: position
    },
    props: [ 'collections', 'types'],
    data() {
      return {
      }
    },
    computed: {
      ...Vuex.mapGetters({
        positions:'index/positions'
      })
    },
    methods:{
      ...Vuex.mapActions({
        deletePositions:'index/deletePositions',
        deletePosition:'index/deletePositions',
      })
    },

  }
</script>
