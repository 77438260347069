<template>
  <tr class="myTr">
    <td style="min-width: 50px;">{{order.ordernum}}</td>
    <td style="min-width: 30px;" v-if="type == 'analitic'">{{order.saloonnum}}</td>
    <td style="min-width: 30px;" v-if="type == 'analitic'">{{order.designer_id}}</td>
    <td style="min-width: 50px;">
      <input v-if="showInput && this.content.claim_order != null" class="form-control mx-input myinput" @change="updateOrder"
             v-model="content.claim_number">
      <span v-if="!showInput">{{content.claim_number}}</span>
    </td>
    <td style="min-width: 50px;">
      <input v-if="showInput" class="form-control mx-input myinput" @change="updateOrder"
             v-model="content.status">
      <span v-if="!showInput">{{content.status}}</span>
    </td>
    <td style="min-width: 30px;" v-if="showField && type == 'analitic'">{{order.order_year}}</td>
    <td style="min-width: 30px;" v-if="showField && type == 'analitic'">{{order.order_months}}</td>
    <td v-if="type == 'analitic'" style="min-width: 50px;">{{region}}</td>
    <td v-if="type == 'analitic'" style="min-width: 50px;">{{content.sumvalue ? Number(content.sumvalue).toFixed(2) : ''}}</td>
    <td v-if="type == 'analitic'" style="min-width: 50px;">{{content.square? Number(content.square).toFixed(4) : ''}}</td>
    <td >
      <date-picker v-if="showInput" input-class="form-control mx-input" @change="updateOrder"
                   format="DD.MM.YYYY" v-model="content.client_agree_date" valueType="format"></date-picker>
      <span v-if="!showInput">{{content.client_agree_date}}</span>
    </td>
    <td><input v-if="showInput" class="form-control mx-input myinput" @change="updateOrder"
               v-model="content.creator" @focus="onFocus">
      <div class="manufactures" v-if="focused">
        <div style="width:100px;" @click="setCreator(mnf)"
             v-for="(mnf, index) in this.$store.state.managerorderlist.manufactures" :key="index">{{mnf.name}}
        </div>
      </div>
      <span v-if="!showInput">{{content.creator}}</span>
    </td>
    <td>
      <input v-if="showInput" class="form-control mx-input myinput" @change="updateOrder"
             v-model="content.sap_number">
      <span v-if="!showInput">{{content.sap_number}}</span>
    </td>
    <td>
      <date-picker v-if="showInput" input-class="form-control mx-input" v-model="content.confect_agree_date"
                   format="DD.MM.YYYY"
                   @change="updateOrder" valueType="format"></date-picker>
      <span v-if="!showInput">{{content.confect_agree_date}}</span>
    </td>
    <td><textarea v-if="showInput" class="form-control mx-input textinput" rows="2" @change="updateOrder"
                  v-model="content.dealer_law"></textarea>
      <span v-if="!showInput">{{content.dealer_law}}</span>
    </td>
    <td v-if="showField">{{order.nameregion_pek}}</td>
    <td><textarea v-if="showInput" class="form-control mx-input textinput" rows="2" @change="updateOrder"
                  v-model="content.delivery"></textarea>
      <span v-if="!showInput">{{content.delivery}}</span>
    </td>
    <td style="max-width: 100px;">{{order.contain}}</td>
    <td style="max-width: 100px;">{{order.decor}}</td>
    <td>
      <date-picker v-if="showInput" input-class="form-control mx-input" v-model="content.ship_date"
                   @change="updateOrder"
                   format="DD.MM.YYYY" valueType="format"></date-picker>
      <span v-if="!showInput">{{content.ship_date}}</span>
    </td>
    <td>
      <date-picker v-if="showInput" input-class="form-control mx-input" v-model="content.arrive_date"
                   @change="updateOrder"
                   format="DD.MM.YYYY" valueType="format"></date-picker>
      <span v-if="!showInput">{{content.arrive_date}}</span>
    </td>
    <td v-if="showField && type == 'analitic'">{{content.dreamdate}}</td>
    <td v-if="showField && type == 'analitic'">{{content.factdate}}</td>
    <td>
      <input v-if="showInput" class="form-control mx-input myinput" @change="updateOrder"
             v-model="content.dealer_bill">
      <span v-if="!showInput">{{content.dealer_bill}}</span>
    </td>
    <td>
      <input v-if="showInput" class="form-control mx-input myinput" @change="updateOrder"
             v-model="content.dealer_add_bill">
      <span v-if="!showInput">{{content.dealer_add_bill}}</span>
    </td>
    <td>
      <textarea v-if="showInput" style="min-width: 200px;" class="form-control mx-input textinput"
                @change="updateOrder"
                v-model="content.rehau_comment"></textarea>
      <span v-if="!showInput">{{content.rehau_comment}}</span>
    </td>
  </tr>
</template>

<script>
  import Acl from '@/utils/acl'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/ru';
  import Http from '../../utils/http'
  import Vuex from 'vuex'

  export default {
    mixins:[Acl],
    data() {
      return {
        content: {},
        focused: false,
      }
    },
    components: {
      DatePicker
    },
    props: ['order', 'showField', 'type'],
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
      }),
      region() {
        if (this.order.zone == 10) {
          return "Россия"
        } else {
          return "Беларусь"
        }
      },
      showInput() {
        if (this.type != 'analitic' && this.can('updateOrders')){
          return true
        }
        return false
      }
    },
    watch: {
      order: function (val) {
        this.setData(val)
      }
    },
    methods: {
      setData() {
        this.content = Object.assign({}, this.order)
      },
      updateOrder() {
        this.focused = false
        Http.post(this.apiUrl + 'ordersv2/updatemanagerorderapi', this.content)
          .then(r => {
            this.$store.commit('managerorderlist/setOrder', r)
            this.$store.dispatch('managerorderlist/getManufactures')
          })
      },
      onFocus() {
        this.focused = true
      },
      setCreator(mnf) {
        this.creator = mnf.name
        this.updateOrder()
      }
    },
    created() {
      this.setData()
    }
  }
</script>

<style scoped>
  .myinput {
    min-width: 50px;
    padding: 5px;
    font-size: 12px;
    height: 34px;
  }

  .textinput{
    min-width: 50px;
    padding: 5px;
    font-size: 12px;
    height: 100%;
  }

  .mx-datepicker {
    font-size: 10px;
    width: 120px;
  }

  .myTr > td {
    min-width: 100px;
    border: solid gray 1px;
  }

  td {
    padding: 2px;
  }

  .manufactures {
    position: absolute;
    witht: 100%;
    background-color: white;
  }
</style>
