<template>
  <section id="step_5" class="clearfix">
    <div class="clearfix_container-loader" v-if="load">
      <img src="../../../assets/RehauLoader.gif" />
    </div>
    <div class="container" :class="isActiveCss">
      <div class="parameter-block clearfix">
        <span>5</span>
        <h2>Размещение заказа</h2>
        <p class="parameter-descr">Ваш заказ сформирован и размещен</p>
      </div><!--/.parameter-block -->
      <div class="placement-wrapper col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-0 clearfix">
        <p class="step5-title text-center">Уважаемый, {{order.surname}} {{order.name}}, Вы разместили заказ на фасады РЕХАУ</p>
        <p class="your-order text-center">Ваш заказ №{{order.order_id}} </p>
          <div class="buttons-step clearfix directions-wrapper">
              <div class="btn-two-wrapper" @click.stop="leftScroll('left')" style="margin-left: 1%">
                  <button class="btn btn-two directions" style="width: 15% !important;"></button>
                  <p>Прокрутить влевло</p>
              </div>
              <div class="btn-two-wrapper"  @click.stop="leftScroll('right')">
                  <p>Прокрутить вправо</p>
                  <button class="btn btn-one directions" style="width: 15% !important;"></button>
              </div>
          </div>
          <div class="scrollme table-responsive" ref="tableResp">
              <div class="col-sm-4">
                  <div class="row">
                      <div class="placement-block">
                          <p class="placement-title">Коллекция</p>
                          <p class="placement-text" v-for="(fin, i) in finishArr" :key="i">{{fin.name}}</p>
                          <p class="placement-text"><b>ИТОГО</b></p>
                      </div><!--/.placement-block -->
                  </div><!--/.row -->
              </div><!--/.col-sm-4 -->
              <div class="col-sm-4">
                  <div class="row">
                      <div class="placement-block text-center">
                          <p class="placement-title">Квадратных метров</p>
                          <p class="placement-text" v-for="(fin, i) in finishArr" :key="i">{{Number(fin.square).toFixed(3)}} м<sup>2</sup></p>
                          <p class="placement-text"><b>{{sumData.square}}</b></p>
                      </div><!--/.placement-block -->
                  </div><!--/.row -->
              </div><!--/.col-sm-4 -->
              <div class="col-sm-4">
                  <div class="row">
                      <div class="placement-block text-right">
                          <p class="placement-title">Сумма</p>
                          <p class="placement-text" v-for="(fin, i) in finishArr" :key="i">{{Number(fin.sum).toFixed(2)}} руб</p>
                          <p class="placement-text"><b>{{sumData.price}}</b></p>
                      </div><!--/.placement-block -->
                  </div><!--/.row -->
              </div><!--/.col-sm-4 -->
              <div class="col-sm-4">
                  <div class="row">
                      <div class="placement-block">
                          <p class="placement-title">Доставка через</p>
                          <p class="placement-text">ТК “ПЭК”</p>
                      </div><!--/.placement-block -->
                  </div><!--/.row -->
              </div><!--/.col-sm-4 -->
              <div class="col-sm-4">
                  <div class="row">
                      <div class="placement-block text-center">
                          <p class="placement-title">Пункт получения</p>
                          <p class="placement-text">{{order.delivery}}</p>
                      </div><!--/.placement-block -->
                  </div><!--/.row -->
              </div><!--/.col-sm-4 -->
              <div class="col-sm-4">
                  <div class="row">
                      <div class="placement-block text-right">
                          <p class="placement-title">Стоимость доставки</p>
                          <p class="placement-text">{{order.money}}</p>
                      </div><!--/.placement-block -->
                  </div><!--/.row -->
              </div><!--/.col-sm-4 -->
          </div>
        <div class="clear"></div>
        <p class="footnote text-center">* Обращаем внимание на то, что заказ не будет запущен в производство без Вашего подтверждения</p>
        <div class="col-sm-6">
          <a :href="this.host + '/cartv2/print?id=' + $route.params.id" target="_blank"><div class="print_blank" style="margin-top:20px;">Распечатать бланк заказа для клиента</div></a>						</div><!--/.col-sm-6 -->
        <div class="col-sm-6">
          <a v-if="user.id != null" :href="this.host + '/cartv2/printdealer?id=' + $route.params.id" target="_blank"><div class="print_blank print_blank_diler" style="margin-top:20px;">Распечатать бланк заказа дилера</div></a>
        </div><!--/.col-sm-6 -->
      </div><!-- /.placement-wrapper -->
      <div class="buttons-step clearfix">

        <a href="/"><button type="button" class="btn-order next-step" style="">Следующий заказ</button></a>					</div><!--/.buttons-step -->
    </div><!-- /.container-fluid -->
  </section>
</template>

<script>
  import Vuex from 'vuex'
  import Http from '@/utils/http'
  
  export default {
    data() {
      return {
        order: {},
        load: false,
        finishArr:[],
        isActiveCss: 'disabled',
          container: {}
      }
    },
      methods: {
          leftScroll(direction) {
              if (direction === 'left') {
                  this.container.scrollLeft -= 250;
              }
              if (direction === 'right') {
                  this.container.scrollLeft += 250;
              }
          },
          initContainer() {
              this.container = this.$refs.tableResp;

              this.container.style.overflowX = 'scroll';
              this.container.style.width = '100%';
              this.container.style.transitionTimingFunction = 'linear';
              this.container.style.transitionDelay = '300ms';
              this.container.style.transition = "position 2s linear 1s";
          }
      },
      mounted: function () {
          this.initContainer();
      },
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        positions: 'index/positions',
        sumAllPositions: 'index/sumAllPositions',
        user: 'auth/user'
      }),
      sumData(){
        var square = 0
        var price = 0
        this.finishArr.map(el => {
          square += +el.square
          price += +el.sum
        })
        return {square:Number(square).toFixed(3), price: Number(price).toFixed(2)}
      }
    },
    created() {
      if (this.$route.params.id != null) {
        this.load = true;
        var money = (this.$route.query.money != null ? '&money=' + this.$route.query.money : '')
        var warehouse = (this.$route.query.warehouse_id != null ? '&warehouse_id=' + this.$route.query.warehouse_id : '')
        var dealer = (this.$route.query.dealer_id != null ? '&dealer_id=' + this.$route.query.dealer_id : '')
        var saloonnum = (this.$route.query.saloonnum != null ? '&saloonnum=' + this.$route.query.saloonnum : '')
        Http.get(this.host + 'cartv2/finish?order_id=' + this.$route.params.id + money + warehouse + dealer + saloonnum)
          .then((r) => { 
            this.isActiveCss = ''
            this.order = r.data.order
            this.finishArr = r.data.finish
            this.load = false;
          }).catch(() => {
            this.$store.commit('index/setOrderId',null)
            this.$router.push('/')
          })
      } else {
        this.$router.push('/')
      }
    },
  }
</script>
<style scoped>

.placement-wrapper .print_blank {
    color:#000000;
    cursor:pointer;
}

.placement-wrapper .print_blank:hover{
    color:#DC143C;
}


.btn-one:after {
    position: absolute;
    content: url(https://api.fronts.shop.rhsolutions.ru/img_dev/arrow.png);
    top: 3px;
    left: 0;
    transform: rotate(270deg);
}

.btn-two:before {
    position: absolute;
    content: url(https://api.fronts.shop.rhsolutions.ru/img_dev/arrow.png);
    top: 2px;
    left: 0;
    transform: rotate(90deg);
}

.btn {
    box-shadow: none !important;
}

.directions-wrapper {
    display: none;
}


@media (max-width: 420px) {
    .directions {
        width: 25% !important;
        margin: 0;
        font-size: 10px;
        text-align: center;
    }

    .directions-wrapper {
        display: flex !important;
        flex-direction: row !important;
    }


    .table-responsive {
        overflow-y: scroll;
        max-height: 38vh;
        min-height: 300px;
    }

    thead {
        position: sticky;
        top: -1px;
        border: 1px solid #ddd;
        background-color: white;
        z-index: 999;
    }
    .btn-two-wrapper {
        display: flex;
        justify-content: center;
    }

    .btn-two-wrapper button {
        height: fit-content;
    }

    .btn-two-wrapper .btn {
        padding: 0;
    }

    .btn-two-wrapper p {
        width: 135px;
    }

    .scrollme {
        display: flex;
        overflow-x: auto;
        padding: 10px;
        white-space: nowrap;
        min-height: min-content;
        text-align: center;
    }

    .placement-title {
        text-align: center;
        height: 40px;
        width: 150px;
    }

    .placement-text {
        text-align: center;
        height: 40px;

    }

    .table-responsive {
        scroll-behavior: smooth;
        max-height: 38vh;
        overflow: scroll;
        min-height: 200px;
    }

    .print_blank_diler {
        float: inherit;
    }

    #step_5 .next-step {
        width: 90%;
    }
}
  .parameter-block h2 {
    color: #000000 !important;
  }

  label.fl {
    display: block;
    cursor: pointer;
  }

  label.fl::before {
    display: inline-block;
    padding-right: 5px;
    vertical-align: top;
    content: url(http://s1.iconbird.com/ico/0612/GooglePlusInterfaceIcons/w18h181338911550clip.png);
  }

  label [type=file] {
    display: none;
  }

  .detail-box {
    padding-left: 135px;
    padding-right: 8px;
  }

  .detail-box img {
    position: absolute;
    width: 130px;
    height: 81px;
    top: 0;
    left: 0;
    border: 1px solid #C7C7C7;
  }
  .clearfix_container-loader {
    align-items: center;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .07);
    display: block;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: all .3s linear;
    width: 100vw;
    z-index: 2500;
  }
  .clearfix_container-loader img {
    width: 12%;
  }
</style>
