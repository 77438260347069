<template>
    <div>
        <div class="row">
            <div class="col-xs-3">
                <input class="form-control" @keyup="findUsers" v-model="search" type="text">
            </div>
            <div class="col-xs-1">
                <button v-if="can('rbacManage')" class="btn btn-primary" @click.prevent="addUser">Добавить
                </button>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <table class="table clients table-hover">
                <thead>
                <tr>
                    <th scope="col">Имя</th>
                    <th scope="col">Фамилия</th>
                    <th scope="col">Электронный адрес</th>
                    <th scope="col">Телефон</th>
                    <th scope="col">Название орагнизации</th>
                    <th scope="col">Ид дизайнера</th>
                    <th scope="col">Права</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody v-for="user in users" v-bind:key="user.id">
                  <tr v-if="edit == null || edit.id != user.id">
                      <td>{{ user.username }}</td>
                      <td>{{ user.surname }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.phone }}</td>
                      <td>{{ user.orgname }}</td>
                      <td>{{ user.designer_id }}</td>
                      <td><a v-if="can('rbacManage')" :href="'/rights/' + user.id"><i class="glyphicon glyphicon-cog"></i></a>
                      </td>
                      <td><a class="glyphicon glyphicon-pencil" @click="toChange(user)" ></a></td>
                      <td>
                          <confirm-button v-if="can('rbacManage')"
                                          :css="'glyphicon glyphicon-trash'"
                                          v-on:confirmation-success="deleteUsers(user.id)">
                          </confirm-button>
                      </td>
                  </tr>
                  <tr v-if="edit != null && edit.id == user.id">
                    <td><input type="text" class="form-control" v-model="edit.username"></td>
                    <td><input type="text" class="form-control" v-model="edit.surname"></td>
                    <td><input type="text" class="form-control" v-model="edit.email"></td>
                    <td><input type="text" class="form-control" v-model="edit.phone"></td>
                    <td>
                      <select class="form-control"  v-model="edit.organization_id">
                        <option value="">Не выбрано</option>
                        <option v-for="dlr in dealers" :key="dlr.id" :value="dlr.id">
                          {{dlr.name}}
                        </option>
                      </select>
                    </td>
                    <td><input type="text" class="form-control" v-model="edit.designer_id"></td>
                    <td></td>
                    <td><a class="glyphicon glyphicon-ok" @click="update"></a></td>
                    <td ><a class="glyphicon glyphicon-remove" @click="edit = {}"></a></td>
                  </tr>
                </tbody>
            </table>
        </div>
        <add-form
                @closeAddForm="closeAddForm"
                @addUser="addUser"
                :isVisible="addModalShow"
                :newUser="newUser"/>
    </div>
</template>

<script>
  import Http from '../../../utils/http'
  import AddForm from './addForm'
  import Acl from '../../../utils/acl'
  import Vuex from 'vuex'
  import confirmButton from "../../../utils/confirmButton";

  export default {
    mixins: [Acl],
    name: 'users',
    data() {
      return {
        addModalShow: false,
        search: '',
        edit: {},
        newUser: {
          email: null,
        },
      }
    },
    components: {
      AddForm, confirmButton

    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        userStatuses: 'app/userStatuses',
        users: 'app/users',
        dealers: 'dealers/datalist'
      }),
    },
    methods: {
      ...Vuex.mapActions({
        getUsers: 'app/getUsers',
        deleteUser: 'app/deleteUser',
        findDealers: 'dealers/find',
        updateUser: 'app/updateUser'
      }),
      ...Vuex.mapMutations({
        setError: 'app/setError',
      }),
      toChange(model){
        this.edit = Object.assign({}, model)
      },
      findUsers(){
        this.getUsers(this.search)
      },
      deleteUsers(id) {
        this.deleteUser(id)
          .then(() => {
            this.getUsers()
          })
      },
      update() {
        this.setError(null)
        this.updateUser(this.edit)
          .then(() =>{
            this.edit = null
            this.findUsers()
          })
      },
      addUser() {
        Http.post(this.apiUrl + 'user/create', {"User": {"email": this.search}})
          .then(() => {
            this.getUsers()
            this.newUser.email = null
            this.closeAddForm()
          })
          .catch((e) => {
            this.setError(e.response)
          })

      },
      closeAddForm() {
        this.addModalShow = false
      },
    },
    created() {
      this.$store.commit('app/setCurrentPageHeader', 'Список пользователей')
      this.getUsers()
      this.findDealers({limit:0})
    }
  }
</script>
<style scoped>
</style>