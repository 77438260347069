<template>
  <section>
      <div class="form-row">
        <div class="col-xs-2">
          <select class="form-control" v-model="form.collection_id">
            <option v-for="col in collections" :key="col.collection_id" :value="col.collection_id">{{col.name}}</option>
          </select>
        </div>
        <div class="col-xs-1">
          <button class="btn btn-primary" @click="createLink">Добавить</button>
        </div>
        <div class="col-xs-7"></div>
        <div class="col-xs-3">
        </div>
      </div>
      <table class="table table-bordered table-hover">
        <tr>
          <th>Название</th>
          <th></th>
          <th></th>
        </tr>
        <tbody v-for="model in links" :key="model.id">
          <tr >
            <td>{{model.name}}{{model.id}}</td>
            <td width="100px;"><vue-confirmation-button
                    :css="'glyphicon glyphicon-trash'"
                    v-on:confirmation-success="deleteData(model.collection_id)">
            </vue-confirmation-button></td>
          </tr>

        </tbody>
      </table>
  </section>
</template>

<script>
  import Acl from '../../utils/acl'
  import Vuex from 'vuex'
  import vueConfirmationButton from '../../utils/confirmButton'
  export default {
    mixins: [Acl],
    name: 'typelinks',
    data () {
      return {
        form:{
          collection_id: null,
        },
      }
    },
    components: {
      VueConfirmationButton: vueConfirmationButton,
    },
    computed: {
      ...Vuex.mapGetters({
        apiUrl: 'app/apiUrl',
        links: 'type/links',
        collections: 'collection/datalist'
      }),
    },
    methods: {
      ...Vuex.mapMutations({
        setMessage: 'app/setMessage',
        setError: 'app/setError',
        setHeader:'app/setCurrentPageHeader'
      }),
      ...Vuex.mapActions({
        getLinks: 'type/getLinks',
        getCollections: 'collection/find',
        addLink: 'type/addLink',
        deleteLink: 'type/deleteLink'
      }),

      findData(){
        this.getLinks({id: this.$route.params.id})
      },
      deleteData(id){
        this.deleteLink({type_id:this.$route.params.id, collection_id:id})
          .then(() => {
            this.findData()
          })
      },
      createLink(){
        this.addLink({type_id:this.$route.params.id, collection_id: this.form.collection_id})
          .then(() => {
            this.findData()
          })

      }
    },
    created() {
      this.setHeader('Связанные коллекции')
      this.findData()
      this.getCollections()
    }
  }
</script>
<style>
  input[type="file"] {
    display: none;
  }
</style>
