<template>
  <div class="pagination" v-if="getTotal > 1">
    {{page}} из {{getTotal}}
    <ul>
      <li v-for="i in visiblelist" :key="i" :class="i == page ? 'active' : '' "><a href="#" @click="set(i)">{{i}}</a>
      </li>
    </ul>
  </div>

</template>

<script>
  export default {
    name: 'pagination',
    props: ['padding', 'page', 'totalpages'],
    data() {
      return {}
    },
    computed: {
      currentPage() {
        return this.page != null ? +this.page : 1
      },
      getPadding() {
        return this.padding != null ? +this.padding : 0
      },
      getTotal() {
        return this.totalpages != null ? Math.ceil(+this.totalpages) : 0
      },
      min() {
        var mn = this.currentPage - this.getPadding
        if (mn > 0) {
          return mn
        }
        return 1
      },
      max() {
        var mx = this.min + 2 * this.getPadding
        return mx > this.getTotal ? this.getTotal : mx
      },
      visiblelist() {
        var arr = []
        for (var i = this.min; i <= this.max; i++) {
          arr.push(i)
        }
        return arr
      }
    },
    methods: {
      set(i) {
        this.$emit('setPage', i)
      }
    },
  }
</script>
<style scoped>
  .pagination {
    position: relative;
    display: block;
    margin: 20px 0;
    border-radius: 4px;
  }

  .pagination > ul {
    background: #d6dbdf;
    color: white;
    padding: 0;
    margin: 0;
    display: inline-block;
    border-radius: 6px;
    word-spacing: -0.5px;
  }

  .pagination li > a, .pagination li > span {
    display: inline-block;
    background: transparent;
    border: none;
    border-left: 2px solid #e4e7ea;
    color: white;
    font-size: 14px;
    line-height: 16px;
    min-height: 41px;
    min-width: 41px;
    outline: none;
    padding: 12px 10px;
    text-align: center;
    transition: .25s ease-out;
  }

  .pagination li.active > a, .pagination li > a:hover, .pagination li.active > span {
    background-color: #4FC7B5;
    color: white;
    border-color: #4FC7B5;
  }

  .pagination li.previous {
    left: 0;
    right: auto;
    border-radius: 6px 0 0 6px;
  }

  .pagination li.next, .pagination li.previous {
    background-color: #d6dbdf;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    border-radius: 0 6px 6px 0;
  }

  .pagination li {
    display: inline-block;
    margin-right: -3px;
    vertical-align: middle;
    word-spacing: normal;
  }
</style>

