<template>
  <div class="container-fluid">
    <errormessage class="col-xs-8 col-xs-offset-2 mt-1 absolutealert"></errormessage>
    <div class="row">
      <div class="col padding_none" style="padding-left:0;padding-right:0;">
        <navbar v-if="showNavbar"/>
        <div class="container-fluid">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Navbar from './components/admin/navbar'
  import Vuex from 'vuex'
  import errormessage from "./components/errormessage";


  export default {
    name: 'app',
    data () {
      return {
        name: 'app',
      }
    },
    components: {
      Navbar,
      errormessage
    },
    computed: {
      ...Vuex.mapGetters({
        isAuthenticated: 'auth/isAuthenticated',
      }),
      showNavbar(){
        if (this.$route.name == 'Home'){
          return false
        }
        if (this.$route.name == 'ThirdStep' || this.$route.name == 'FourthStep'|| this.$route.name == 'FifthStep'){
          return false
        }
        if (this.$router.path == '/login'){
          return false
        }
        if (this.isAuthenticated){
          return true
        }
        return false
      }
    },
    methods: {
      check () {
        this.$store.dispatch('auth/check')
      }
    },
    created () {
      this.$store.dispatch('app/setDictionary')
    }
  }
</script>

<style>
  .absolutealert{
    position: absolute;
    z-index: 1000;
  }
</style>

