<template>
  <section  class="clearfix">
    <div class="container">
        <div class="clear"></div>
        <div class="clear" style="height:5px;"></div>
        <div class="col-sm-5">
          <div class="form-group contact-block">
          </div>
        </div>
        <div class="clear" style="height:10px;"></div>
        <div v-if="user != null && user.id != null" class="col-sm-3">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-email required">
              <label  class="control-label active" :style="{color: canSaloonnum ? 'gray' : '#DC143C'}" >Номер салона {{this.saloonreal}}</label>
              <input
                     type="text" class="form-control formname" v-model="saloonnum" @keyup="findSaloons()" placeholder="Номер салона">
              <div class="dinamicSelect">
                  <div class="col-xs-12"  v-for="saloon in saloons" :key="saloon.id" @click="setSaloon(saloon)">
                    {{ saloon.name }}
                    {{ saloon.saloonnum }}
                    {{ saloon.adress }}
                  </div>
                </div>

              <div class="help-block"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="form-group contact-block">
            <div class="form-group field-orderscont-comment">
              <label class="control-label active" >Комментарий</label>
              <textarea class="form-control" rows="1" v-model="comment" placeholder="Комментарий" @change="updateOrder" style="width:100%;height:43px;"></textarea>
              <div class="help-block"></div>
            </div>
          </div>
          
        </div>
        <div class="clear" style="height:20px;"></div>
        <div class="col-sm-12">
          <div class="col-sm-2">
            <div class="form-group field-orders-dpackage">
              <input type="hidden" :value="greatPack"><label>
              <input type="checkbox" class="checkbox" @change="updateOrder" v-model="greatPack">
              <markdown
                :label=" ' Улучшенная упаковка'"
                :description="' (П-образный профиль по периметру фасадов), ' + settings.greatpack + ' руб. / м2. \n'+
                  'Настоятельно рекомендуем заказывать фасады с улучшенной упаковкой.'"
                :hiddenStyle="'-30px 100px'"
                :labelStyle="'margin-left:5px;'"
              ></markdown>
              <div class="clear"></div>
            </label>
            </div>
          </div>
          <div class="col-sm-7">
          </div>
        </div>
        <div class="col-sm-6"><div class="buttons-step clearfix">
          <a @click="$router.push('/cart/' + orderId)"><button type="button" class="prev-step2">Предыдущий шаг</button></a>
        </div>
        </div>
      <div class="col-sm-6">
        <h4 v-if="!greatPack" class="text text-right" style="color:#D03D72;">
        При отсутствии улучшенной упаковки возникает риск повреждения при транспортировке,
        за который компания РЕХАУ ответственности не несет
        </h4>
      </div>
      <div class="col-sm-12">
        <h3>Вы можете забрать заказ со склада РЕХАУ после его готовности. Другие способы доставки просьба согласовывать с ответственным менеджером РЕХАУ при подтверждении заказа.</h3>
      </div>
      <div class="col-sm-12 hw" v-if=" canAccept">
        <div class="col-xs-12"
             @click="$router.push('/finish/' + order.order_id  + '?dealer_id= ' + user.organisation.id + ' && warehouse_id=' +wh.id + '&amp;money=' + '&amp;saloonnum=' + saloonreal)"
             v-for="wh in warehouses" :key="wh.id">
          <div class="col-xs-3 h4">
            {{ wh.name }}
          </div>
          <div class="col-xs-6 h4 text-center">
            {{ wh.adress }}
          </div>
          <div class="col-xs-3 h4 text-right" id="order-sent" style="color:white">
            Отправить заказ
          </div>
        </div>
      </div>
      </div>
  </section>
</template>
<script>
  import Markdown from "../../../utils/markdown";
  import Vuex from 'vuex'

  export default {
    props:['zone'],
    components:{
      Markdown:Markdown
    },
    data(){
      return {
        orderId: null,
        orderPrice: null,
        organisationName: null,
        surname: null,
        name: null,
        phone: null,
        email:null,
        lenght: null,
        width: null,
        hight: null,
        weight: null,
        cityId: '',
        comment: null,
        hardPack: false,
        greatPack: false,
        selected: null,
        longitude: null,
        latitude: null,
        addrlocation: null,
        designerId: null,
        saloonnum: null,
        saloonreal: '',
        sort:1,
      }
    },
    methods: {
      ...Vuex.mapActions({
        finddealers: 'dealers/find',
        findwarehouses: 'warehouses/find',
        updateorder: 'order/update'
      }),
      ...Vuex.mapMutations({
        setdealers: 'dealers/set'
      }),
      setSaloon(saloon) {
        this.saloonreal = saloon.saloonnum
        this.saloonnum = saloon.saloonnum
        this.updateOrder()
        this.setdealers([])
      },
      findSaloons() {
        this.finddealers({
          query: this.saloonnum,
          type_id: 20,
          limit: 5,
        })
      },
      getInfoByOrderId(){
          this.greatPack = this.order.dpackage
          this.comment = this.order.comment
          this.comment = this.order.comment
          this.saloonreal= this.order.saloonnum
      },
      getWarehouse() {
        this.findwarehouses({zone: this.zone})
      },
      updateOrder() {
        var newOrd = Object.assign({}, this.order)
        newOrd.dpackage = this.greatPack
        newOrd.comment = this.comment
        newOrd.saloonnum = this.saloonreal
        this.updateorder(newOrd)
      },
    },
    computed: {
      ...Vuex.mapGetters({
        host: 'index/host',
        positions: 'index/positions',
        sumAllPositions: 'index/sumAllPositions',
        user: 'auth/user',
        points: 'index/points',
        itemTowns: 'index/itemTowns',
        saloons: 'dealers/datalist',
        settings: 'app/settings',
        warehouses: 'warehouses/datalist',
        order: 'order/order',
      }),
      items(){
        return this.itemTowns
      },
      dealers(){
        var points = this.points
        if (this.sort == 2) {
          return points.sort(function(a, b){
            return a.money - b.money
          })
        } else {
          return points.sort(function (a, b) {
            return a.days - b.days
          })
        }
      },
      canAccept(){
        if (this.canEmail == false){
          return false
        }
        if (this.canName == false){
          return false
        }
        if (this.canPhone == false){
          return false
        }
        if (this.canSurname == false){
          return false
        }
        if (this.canSaloonnum == false){
          return false
        }
        return true
      },
      canSaloonnum(){
        if (this.order.saloonnum == null || this.order.saloonnum == '') {
          return false
        }

        return true
      },
      canPhone(){
        if (this.order.phone == null || this.order.phone == '') {
          return false
        }
        return true
      },
      canSurname(){
        if (this.order.surname == null || this.order.surname == '') {
          return false
        }
        return true
      },
      canName(){
        if (this.order.name == null || this.order.name == '') {
          return false
        }
        return true
      },
      canEmail(){
        if (this.order.email == null || this.order.email == '') {
          return false
        }
        return true
      },
    },
    created(){
      if (+this.$route.params.id != null) {
        this.orderId = +this.$route.params.id
        if (this.user != null && this.user.organisation != null && (this.saloonreal == null || this.saloonreal == "")){
          this.saloonreal = this.user.organisation.saloonnum
          if (this.saloonreal == null) {
            this.saloonreal = ''
          } 
        }
      }
      this.getInfoByOrderId()
      this.getWarehouse()
    },
    mounted: function() {

    }
  }

</script>
<style>
@media (max-width: 420px) {
    .hw > .col-xs-12 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        position: relative;
        right: 21%;
    }

    .hw > .col-xs-12 > .col-xs-3 {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .hw > .col-xs-12 {
        border-top: 1px solid #E7E7E7;
    }

    .hw > .col-xs-12 > .col-xs-6 {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    #order-sent {
        display: flex;
        justify-content: center;
        color: rgb(79, 199, 181) !important;
        padding-bottom: 10px;
    }
}
  .dinamicSelect{
    position: absolute;
    z-index: 2;
    background-color: white;
  }

  .dinamicSelect :hover{
   background-color: #4FC7B5;
   color: white;
  }

  .hw :hover{
    background-color:#4FC7B5  ;
    color: white;
  }
</style>
