<template>
  <section >
    <label @mouseover="upHere = true" @mouseleave="upHere = false" class="product-type label-head" :style="labelStyle">{{label}}</label>
    <div class="col-xs-12 hidden-help" :style="{margin: hStyle, display: upHere == true ? 'block' : 'none'}">
      <img v-if="isImg" :src="imgSrc" alt="">
      {{description}}
    </div>
  </section>
</template>

<script>
  export default {
    name: "markdown",
    data() {
      return {
        upHere: false
      }
    },
    props:['description', 'label', 'imgSrc', 'hiddenStyle', 'labelStyle'],
    computed:{
      isImg(){
        if (this.imgSrc != null && this.imgSrc.length > 0) {
          return true
        }
        return false
      },
      hStyle () {
        if (this.hiddenStyle != null) {
          return this.hiddenStyle
        }
        return ''
      },
      lStyle () {
        if (this.labelStyle != null) {
          return this.labelStyle
        }
        return ''
      }
    }
  }
</script>

<style scoped>
  .hidden-help{
    height: auto;
    z-index: 10;
  }
  .product-type:after{
    right: -5px;
    position: relative;
    left: auto;

  }
  .label-head{
    font-size: 14px;
  }
</style>
