import Http from '../../utils/http'

const state = {
  stepThree: false,
  collection: {
    name: 'Rauvisio Brilliant',
    bigImgUrl: '/img/bg_facade_1.jpg',
    id: 0,
    descriptrtion: 'За счет особой технологии глубокого прокрашивания всех слоев фасадов Brilliant создается стереоэффект поверхности, что визуально привлекает внимание, расширяет интерьер кухни и создает эффект непрерывного блеска.'
  },
  facade:null,
  host: 'https://api.fronts.shop.rhsolutions.ru/',
  positions:[],
  orderId: null,
  bluredIdx: 0,
  bluredElement: 1,
  points:[],
  itemTowns:[],
}

const getters = {
  host: state => state.host,
  itemTowns: state => state.itemTowns,
  points: state => state.points,
  stepThree: state => state.stepThree,
  blured: state => state.bluredIdx,
  collection: state => state.collection,
  facade: state => state.facade,
  positions: state => state.positions,
  orderId: state => state.orderId,
  sumAllPositions: state => {
    let sum = 0
    for (let i = 0; i < state.positions.length; i++) {
      sum += +state.positions[i].sumvalue
    }
    return Number((sum).toFixed(2))
  },
  existNullSum: state => {
    for (let i = 0; i < state.positions.length; i++) {
      if (state.positions[i].sumvalue == 0) {
        return true
      }
    }
    return false
  }

}

const actions = {
  uploadPosition(context, formData) {
    Http.post('https://api.fronts.shop.rhsolutions.ru/ordersv2/positionuploadfile',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(() => {
      context.dispatch('getPositions')
    })
  },
  getPositions(context) {
    return new Promise((resolve, reject ) => {
      Http.get(context.getters.host + 'ordersv2/positionlist?order_id=' + context.state.orderId)
        .then((response) => {
          context.commit('setPositions', response.data)
          resolve(response)
        })
        .catch((err) => {
          context.commit('setOrderId', null)
          reject(err)
        })
    })
  },
  addPosition(context, facadeId, borderId) {
    Http.post('https://api.fronts.shop.rhsolutions.ru' + '/ordersv2/addposition', {
      price_id: facadeId,
      border_id: borderId,
      order_id: context.state.orderId,
    }).then((response) => {
      context.commit('setOrderId', response.data.order_id)
      context.dispatch('getPositions')
    }).catch((error) =>{
      console.log(error)
    });
  },
  copyPosition(context, positionId) {
    Http.get('https://api.fronts.shop.rhsolutions.ru/ordersv2/copyposition?position_id=' + positionId)
      .then((response) =>{
        context.commit('setOrderId', response.data.order_id)
        context.dispatch('getPositions')
      })
      .catch((error) => {
        console.log(error)
      });
  },
  deletePositions(context, positionId) {
    let pos = ''
    if (positionId > 0) {
      pos = '&position_id=' + positionId
    }
    Http.get('https://api.fronts.shop.rhsolutions.ru/ordersv2/deletepositions?order_id=' + context.state.orderId + pos)
      .then(() => {
        context.dispatch('getPositions')
      })
      .catch((error) => {
        console.log(error)
      });
  },
}

const mutations = {
  setFacade(state, facade) {
    state.facade = facade
  },
  setItemTowns(state, itemTowns) {
    state.itemTowns = itemTowns
  },
  setSpecify(state, payload) {
    for (var i = 0; i < state.points.length; i++) {
      if (state.points[i].city_id == payload.city_id) {
        state.points[i].money = payload.money
        state.points[i].preparation = payload.days
      }
    }
  },
  setPoints(state, points) {
    state.points = points
  },
  nextBlure(state) {
    if (state.bluredElement < 12) {
      state.bluredElement += 1;
    } else {
      if (state.positions.length > (state.bluredIdx + 1)) {
        state.bluredIdx += 1
        state.bluredElement = 0
      }
    }
    var el = document.getElementById(state.bluredIdx + '' + state.bluredElement)
    if (el) {
      el.focus()
    }
  },
  previosBlure(state) {
    if (state.bluredElement > 1) {
      state.bluredElement -= 1;
    } else {
      if (state.bluredIdx > 0) {
        state.bluredIdx -= 1
        state.bluredElement = 12
      }
    }
    var el = document.getElementById(state.bluredIdx + '' + state.bluredElement)
    if (el) {
      el.focus()
    }
  },
  setBlure(state, idx) {
    state.bluredIdx = idx
  },
  changeCollection(state, collectionId) {
    state.chosenCollection = collectionId
  },
  setPositions(state, positions) {
    state.positions = positions
  },
  setOrderId(state, orderId) {
    state.orderId = orderId
  },
  setCollectionId(state, id) {
    state.collection.id = id
  },
  setCollectionName(state, name) {
    state.collection.name = name
  },
  setCollectionImg(state, url) {
    state.collection.bigImgUrl = url
  },
  setCollectionDescription(state, description) {
    state.collection.description = description
  },
  stepThree(state, isThree) {
    state.stepThree = isThree
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

